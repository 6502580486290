.navbar__wrapper, 
.tabs__container {
  display: none;
}
* {
  -webkit-print-color-adjust: exact;
}

.people-search__filters, 
.people-search__more,
.insights-search__filters,
.button__see-more,
.team-landing__header__filters,
.insights-search__more,
.team-landing__more {
  display: none;
}

.component-card-container {

  .component-card {
    margin-bottom: 1rem;

    .component-card__icon,
    .component-card__more {
      display: none;
    }
  }  
}

.contacts__container,
.team-landing__landing {  

  .contact-card {
    margin-bottom: 0;

    &__image {
      display: none;
    }

    width: 100%;
  }
}

//bio detail
.bio-hero__mobile-image, 
.section__aside {
  display: none;
}

//service detail
.experience {
  .button__container {
    display: none;
  }
}

footer {
  display: none;
  //todo: need to show copyright
}

em {
  font-family: 'Segoe UI';
}
.section__main {
  width: 100%; 
}
 .section--component{
   display:block;
 }
 .cookie.cookie__alternate{
   display: none;
 }
 .rich-text__responsive-table{
   max-width: 100%;
 }
 .rich-text table{
   width:100%;
 }
.section--component, .component-card-container{
  display: block;
}
.component-card-container--three .component-card, .component-card-container--full .component-card{
  width:100%;
}
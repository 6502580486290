@font-face {
  font-family: "Graphik";
  src: url("/assets/public/fonts/Graphik-Extralight.woff2") format("woff2"), url("/assets/public/fonts/Graphik-Extralight.woff") format("woff"), url("/assets/public/fonts/Graphik-Extralight.ttf") format("truetype"), url("/assets/public/fonts/Graphik-Extralight.svg#Graphik") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("/assets/public/fonts/Graphik-ExtralightItalic.woff2") format("woff2"), url("/assets/public/fonts/Graphik-ExtralightItalic.woff") format("woff"), url("/assets/public/fonts/Graphik-ExtralightItalic.ttf") format("truetype"), url("/assets/public/fonts/Graphik-ExtralightItalic.svg#Graphik") format("svg");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("/assets/public/fonts/Graphik-Light.woff2") format("woff2"), url("/assets/public/fonts/Graphik-Light.woff") format("woff"), url("/assets/public/fonts/Graphik-Light.ttf") format("truetype"), url("/assets/public/fonts/Graphik-Light.svg#Graphik") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("/assets/public/fonts/Graphik-LightItalic.woff2") format("woff2"), url("/assets/public/fonts/Graphik-LightItalic.woff") format("woff"), url("/assets/public/fonts/Graphik-LightItalic.ttf") format("truetype"), url("/assets/public/fonts/Graphik-LightItalic.svg#Graphik") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("/assets/public/fonts/Graphik-Regular.woff2") format("woff2"), url("/assets/public/fonts/Graphik-Regular.woff") format("woff"), url("/assets/public/fonts/Graphik-Regular.ttf") format("truetype"), url("/assets/public/fonts/Graphik-Regular.svg#Graphik") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("/assets/public/fonts/Graphik-RegularItalic.woff2") format("woff2"), url("/assets/public/fonts/Graphik-RegularItalic.woff") format("woff"), url("/assets/public/fonts/Graphik-RegularItalic.ttf") format("truetype"), url("/assets/public/fonts/Graphik-RegularItalic.svg#Graphik") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("/assets/public/fonts/Graphik-Semibold.woff2") format("woff2"), url("/assets/public/fonts/Graphik-Semibold.woff") format("woff"), url("/assets/public/fonts/Graphik-Semibold.ttf") format("truetype"), url("/assets/public/fonts/Graphik-Semibold.svg#Graphik") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("/assets/public/fonts/Graphik-SemiboldItalic.woff2") format("woff2"), url("/assets/public/fonts/Graphik-SemiboldItalic.woff") format("woff"), url("/assets/public/fonts/Graphik-SemiboldItalic.ttf") format("truetype"), url("/assets/public/fonts/Graphik-SemiboldItalic.svg#Graphik") format("svg");
  font-weight: 600;
  font-style: italic;
}
@keyframes minimalDropdownFadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes homepageArrowFadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

html, body {
  height: 100%;
  scroll-padding-top: 71px;
}

body {
  margin: 0;
  font-size: 16PX;
  font-family: "Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

div, section {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
}

a, input, button {
  color: inherit;
  display: inline-block;
  box-sizing: border-box;
  -webkit-appearance: none;
  font: inherit;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  line-height: 1;
  background: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
}

input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

form {
  margin: 0;
}

[v-cloak] {
  display: none !important;
}

ol, ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

select {
  cursor: pointer;
  border: none;
  border-radius: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}

div:active {
  outline: none;
}

legend {
  padding: 0;
  display: table;
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

.main__min-height {
  min-height: calc(100% - 276px);
}

.link {
  position: relative;
  transition: color 150ms ease-in-out;
}
.link::after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 0;
  right: 0;
  border-bottom: solid 1px;
}
.link--active::after {
  content: none;
}
.link__row {
  display: flex;
}
.link__row .link:not(:first-child) {
  margin-left: 1rem;
}
.link--hover, .link:hover:not(.link--active), .link:focus:not(.link--active) {
  color: #c95555;
}
.link--inactive,
.link [disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.link--inactive:hover, .link--inactive:focus,
.link [disabled]:hover,
.link [disabled]:focus {
  color: inherit;
}
.link--has-icon, .link--external {
  margin-bottom: 1px;
}
.link--has-icon::before, .link--external::before {
  display: inline-block;
  transform: translateY(3px);
  padding-right: 0.25em;
  border-color: rgba(0, 0, 0, 0);
}
.link--has-icon::after, .link--external::after {
  left: 1.25em;
}
.link--has-icon:hover:not(.link--inactive)::before, .link--external:hover:not(.link--inactive)::before, .link--has-icon:hover[disabled]::before, .link--external:hover[disabled]::before, .link--has-icon:focus:not(.link--inactive)::before, .link--external:focus:not(.link--inactive)::before, .link--has-icon:focus[disabled]::before, .link--external:focus[disabled]::before {
  transition: color 150ms ease-in-out;
  color: #e06565;
}
.link--swoopy {
  position: relative;
}
.link--swoopy:after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0px;
  height: 1px;
  margin: 1px 0 0;
  transition: all 0.3s ease-out, opacity 0.1s ease-out 0.2s;
  opacity: 0;
  background-color: #ffffff;
  right: 0;
  left: auto;
}
.link--swoopy:hover:after {
  left: 0;
  right: auto;
  transition: all 0.25s ease-out;
  width: 100%;
  opacity: 1;
}

.icon__print {
  display: none;
}
@media (min-width: 720px) {
  .icon__print {
    display: block;
  }
}

.animated {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  will-change: transform, opacity;
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slideDown {
  0% {
    -moz-transform: translateY(-100%);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes slideDown {
  0% {
    -o-transform: translateY(-100%);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.animated.slideDown, .table-slideDown .sticky-table-header.fixed-header.animated {
  -webkit-animation-name: slideDown;
  -moz-animation-name: slideDown;
  -o-animation-name: slideDown;
  animation-name: slideDown;
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
  }
}
@-moz-keyframes slideUp {
  0% {
    -moz-transform: translateY(0);
  }
  100% {
    -moz-transform: translateY(-100%);
  }
}
@-o-keyframes slideUp {
  0% {
    -o-transform: translateY(0);
  }
  100% {
    -o-transform: translateY(-100%);
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
.animated.slideUp {
  -webkit-animation-name: slideUp;
  -moz-animation-name: slideUp;
  -o-animation-name: slideUp;
  animation-name: slideUp;
}

@font-face {
  font-family: "icomoon";
  src: url("/assets/public/fonts/icons/icomoon.eot?8uucnu");
  src: url("/assets/public/fonts/icons/icomoon.eot?8uucnu#iefix") format("embedded-opentype"), url("/assets/public/fonts/icons/icomoon.ttf?8uucnu") format("truetype"), url("/assets/public/fonts/icons/icomoon.woff?8uucnu") format("woff"), url("/assets/public/fonts/icons/icomoon.svg?8uucnu#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.icon__dropdown {
  background-color: #ffffff;
}
.icon:before, .global-search__search:before, .people-search__search:before, .regular-input__search:before, .link--external:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon__chevron-small-down:before {
  content: "\e90e";
}

.icon__chevron-small-left:before {
  content: "\e90f";
}

.icon__chevron-small-right:before {
  content: "\e910";
}

.icon__chevron-small-up:before {
  content: "\e911";
}

.icon__chevron-thin-down:before {
  content: "\e912";
}

.icon__chevron-thin-left:before {
  content: "\e913";
}

.icon__chevron-thin-right:before {
  content: "\e914";
}

.icon__chevron-thin-up:before {
  content: "\e915";
}

.icon__download:before {
  content: "\e900";
}

.icon__twitter:before {
  content: "\e918";
}

.icon__vimeo:before {
  content: "\e90c";
}

.icon__search:before, .global-search__search:before, .people-search__search:before, .regular-input__search:before {
  content: "\e909";
}

.icon__facebook:before {
  content: "\e902";
}

.icon__instagram:before {
  content: "\ea92";
}

.icon__linkedin:before {
  content: "\e903";
}

.icon__print:before {
  content: "\e907";
}

.icon__quotes:before {
  content: "\e908";
}

.icon__close:before {
  content: "\e901";
}

.icon__thin-close:before {
  content: "\e917";
}

.icon__external:before, .link--external:before {
  font-size: 18px;
  padding-right: 0.1em;
  top: 0.05em;
  position: relative;
  content: "\e904";
}

.icon__menu:before {
  content: "\e905";
}

.icon__minus:before {
  content: "\e906";
}

.icon__plus:before {
  content: "\e90a";
}

.icon__share:before {
  font-size: 16px;
  padding-right: 0.15em;
  content: "\e90d";
}

.icon__map:before {
  content: "\e916";
}

.icon__play3:before {
  content: "\ea1c";
}

.bio-hero {
  background-color: #1e283c;
  color: #ffffff;
  position: relative;
}
.bio-hero__image {
  display: none;
  background-repeat: no-repeat;
  position: relative;
}
.bio-hero__mobile-image {
  background-repeat: no-repeat;
  background-size: 129%;
  background-position-x: center;
  width: 100%;
  height: 0;
  padding-top: 69%;
}
.bio-hero__left-bottom {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  padding-top: 32px;
  padding-bottom: 32px;
}
.bio-hero__section {
  margin-top: 32px;
}
.bio-hero__name {
  display: inline-block;
}
.bio-hero__name--audio {
  margin-right: 22px;
  display: inline;
}
.bio-hero__titles {
  padding-top: 8px;
}
.bio-hero__title {
  opacity: 0.5;
}
.bio-hero__title:not(:first-child) {
  margin-top: 4px;
}
.bio-hero__contact-item:not(:first-child) {
  margin-top: 6px;
}
.bio-hero__contact {
  transition: opacity 0.3s ease-in-out;
}
.bio-hero__contact:hover, .bio-hero__contact:focus {
  opacity: 0.5;
}
.bio-hero__prefix {
  padding-right: 0.5em;
}
.bio-hero__secondary {
  opacity: 0.5;
  padding-left: 0.5em;
}
.bio-hero__secondary:before {
  content: "(";
}
.bio-hero__secondary:after {
  content: ")";
}
.bio-hero__pronoun {
  opacity: 0.5;
}
.bio-hero__pronoun:before {
  content: "(";
}
.bio-hero__pronoun:after {
  content: ")";
}
.bio-hero__social-links {
  margin-top: 20px;
}
.bio-hero__social-link {
  transition: opacity 0.3s ease-in-out;
}
.bio-hero__social-link:hover, .bio-hero__social-link:focus {
  opacity: 0.5;
}
.bio-hero__social-link:not(:first-child) {
  margin-left: 16px;
}
.bio-hero__image-container {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.bio-hero__audio {
  background: url("/assets/public/images/audio-white.svg") no-repeat;
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-left: 2px;
}
@media (min-width: 720px) {
  .bio-hero__mobile-image {
    display: none;
  }
  .bio-hero__image {
    background-size: auto 100%;
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-position: right 19% center;
  }
  .bio-hero__container {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    position: relative;
  }
  .bio-hero__left-bottom {
    padding: 32px 0;
  }
  .bio-hero__top-right {
    min-width: 45%;
    background: rgba(0, 0, 0, 0);
    display: flex;
    align-items: flex-end;
  }
  .bio-hero__image-container {
    padding-bottom: 90%;
  }
}
@media (min-width: 960px) {
  .bio-hero {
    padding-top: 84px;
    min-height: 430px;
  }
  .bio-hero__image {
    background-size: cover;
    width: 100%;
  }
  .bio-hero__left-bottom {
    padding: 0 0 90px 0;
  }
  .bio-hero__section {
    margin-top: 24px;
  }
  .bio-hero__details {
    display: flex;
  }
  .bio-hero__detail {
    flex: 1;
  }
  .bio-hero__detail:not(:first-child) {
    padding-left: 24px;
  }
}
@media (min-width: 1440px) {
  .bio-hero {
    height: 38vw;
    padding-top: 0;
  }
  .bio-hero__left-bottom {
    padding: 0;
  }
  .bio-hero__container {
    top: 50%;
    transform: translateY(-50%);
  }
  .bio-hero__image {
    background-position: center;
    background-position-y: bottom;
    background-size: cover;
    width: 100%;
  }
}
@media (min-width: 720px) {
  .bio-hero--dark-theme {
    color: #000000;
  }
  .bio-hero--dark-theme .bio-hero__audio {
    background: url("/assets/public/images/audio.svg") no-repeat;
    background-size: 1rem 1rem;
  }
}

.button, .rich-text .button,
.rich-text--smaller .button, .rich-text a.button,
.rich-text a .button,
.rich-text--smaller a.button,
.rich-text--smaller a .button {
  line-height: 1;
  padding: 8px 20px;
  padding-bottom: 7px;
  border: solid 2px;
  transition: padding 150ms ease-in-out, margin 150ms ease-in-out;
  color: #e06565;
  white-space: nowrap;
}
.button__row {
  padding: 1rem;
  display: flex;
  margin: 0.5rem -1rem;
}
.button__row--dark {
  background-color: #1e283c;
}
.button__row--dark-image {
  background-color: #1e283c;
  background-image: url(https://res.cloudinary.com/simpleview/image/upload/c_fill,f_auto,h_322,q_75,w_500,o_25/v1/clients/chicago/Aerial_CAC_e5dcacf0-7c13-49be-b765-d928be6d44c7.jpg);
  background-size: cover;
  background-position: center;
}
.button__spacing-top {
  margin-top: 24px;
}
.button__row-item:not(:first-child) {
  margin-left: 1rem;
}
.button--yellow, .button--on-dark-image {
  color: #f9b36a;
}
.button--white, .button--on-dark-background {
  color: #ffffff;
}
.button--light-font {
  font-weight: 500;
}
.button--hover, .button:hover, .rich-text a.button:hover,
.rich-text a .button:hover,
.rich-text--smaller a.button:hover,
.rich-text--smaller a .button:hover, .button:focus, .rich-text a.button:focus,
.rich-text a .button:focus,
.rich-text--smaller a.button:focus,
.rich-text--smaller a .button:focus {
  padding: 12px 24px;
  padding-bottom: 11px;
  margin: -4px;
}
.button:focus, .rich-text a.button:focus,
.rich-text a .button:focus,
.rich-text--smaller a.button:focus,
.rich-text--smaller a .button:focus {
  outline: 0;
}
.button--inactive, .button[disabled], .rich-text a[disabled].button,
.rich-text a [disabled].button,
.rich-text--smaller a[disabled].button,
.rich-text--smaller a [disabled].button {
  opacity: 0.5;
  cursor: not-allowed;
}
.button--inactive:hover, .button--inactive:focus, .button[disabled]:hover, .button[disabled]:focus {
  margin: 0;
  padding: 8px 20px;
  padding-bottom: 7px;
}
.button__see-more {
  margin-bottom: 30px;
}
.button__container {
  margin-top: 48px;
}

.color__navy {
  color: #1e283c;
}
.color__navy--dark {
  color: #0b1a2b;
}
.color__blue {
  color: #156289;
}
.color__blue--dark {
  color: #0c5273;
}
.color__cyan {
  color: #039ecc;
}
.color__cyan--dark {
  color: #048db6;
}
.color__teal {
  color: #3da3a3;
}
.color__teal-dark {
  color: #318c89;
}
.color__coral {
  color: #e06565;
}
.color__coral--dark {
  color: #c95555;
}
.color__yellow {
  color: #f9b36a;
}
.color__yellow--dark {
  color: #f2985a;
}
.color__gray {
  color: #7D7E81;
}
.color__gray--dark {
  color: #7d7e81;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
}

.experience {
  width: 100%;
}
.experience__title {
  margin-bottom: 24px;
}
.experience__item:not(:first-child) {
  margin-top: 32px;
  padding-top: 32px;
  border-top: solid 1px #b4b4b4;
}
.experience__item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.experience__prior-footnote {
  margin-top: 48px;
}
.experience__more-button {
  padding-top: 20px;
}
@media (min-width: 960px) {
  .experience__title {
    margin-bottom: 48px;
  }
  .experience__item {
    display: flex;
    justify-content: space-between;
  }
  .experience__label {
    width: 34%;
    padding-right: 10px;
  }
  .experience__content {
    width: 51%;
  }
}

.expertise {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.expertise-title {
  text-transform: uppercase;
}
.expertise__main {
  flex-direction: column-reverse;
}
.expertise__side-nav {
  width: 50%;
  padding: 1rem;
}
.expertise__side-nav__container--sticky {
  position: fixed;
  top: 135px;
}
.expertise__side-nav__container__header {
  margin-top: 5px;
}
.expertise__side-nav__container__text {
  margin-bottom: 20px;
}
.expertise__main {
  width: 100%;
  display: flex;
}
@media (min-width: 720px) {
  .expertise__main {
    flex-direction: row-reverse;
  }
}

@media (min-width: 720px) {
  .industries {
    border-right: 1px solid #b4b4b4;
  }
}

@media (min-width: 720px) {
  .expertise__section {
    width: 50%;
  }
}

@media (min-width: 720px) {
  .practices {
    padding-left: 75px;
  }
}

.expertise-item {
  margin: 20px 0;
  cursor: pointer;
}
.expertise-item .link:after {
  content: none;
}
.expertise-item__container {
  display: flex;
  align-items: center;
}
.expertise-item__subnav {
  overflow: hidden;
}
.expertise-item__secondary-subnav {
  margin-left: 15px;
}
.expertise-item__header {
  position: relative;
}
.expertise-item__header__name-part {
  white-space: nowrap;
}
.expertise-item__header__name-part:after {
  font-size: 0.4em;
  vertical-align: middle;
  margin-left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.expertise-item__header--closed .expertise-item__header__name-part:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90a";
}
.expertise-item__header--open .expertise-item__header__name-part:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e906";
}
.expertise-accordion-item {
  margin: 10px 0;
  overflow: hidden;
  cursor: pointer;
}
@media (min-width: 960px) {
  .expertise-accordion-item__side-nav {
    width: 30%;
  }
}

.footer {
  background-color: #1e283c;
  color: #ffffff;
  padding: 30px 0;
  z-index: 2;
  position: relative;
  font-variant-ligatures: no-common-ligatures;
}
.footer__container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
}
.footer__row:not(:first-child) {
  margin-top: 28px;
}
.footer__faded {
  opacity: 0.5;
}
.footer__link {
  transition: color 0.35s ease-out, opacity 150ms ease-in-out;
}
.footer__link:hover {
  opacity: 1;
  color: #ffffff !important;
}
.footer .footer__link, .footer .link--body4 {
  transition: color 0.35s ease-out, opacity 150ms ease-in-out;
  opacity: 0.5;
  font-size: 14px;
}
.footer .footer__link:hover, .footer .link--body4:hover {
  opacity: 1 !important;
  color: #ffffff !important;
}
.footer .link--body4 {
  font-size: 12px;
}
.footer__links {
  display: flex;
}
.footer__link-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer__caption {
  margin-top: 8px;
}
.footer__form {
  margin-top: 8px;
}
.footer__form .searchbar__right {
  margin-left: 0;
}
.footer__form-input {
  margin-top: 10px;
}
.footer__form .searchbar__placeholder {
  left: 14px;
}
.footer__social-links {
  display: flex;
  margin-top: 16px;
}
.footer__social-link {
  transition: opacity 0.3s ease-in-out;
}
.footer__social-link:hover, .footer__social-link:focus {
  opacity: 0.5;
}
.footer__social-link:not(:first-child) {
  margin-left: 18px;
}
.footer__divider-container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
}
.footer__divider {
  height: 2px;
  background-color: #000000;
  margin-top: 40px;
  margin-bottom: 24px;
  opacity: 0.3;
}
.footer__copyright {
  margin-top: 24px;
}
.footer__desktop {
  display: none;
}
.footer__split-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer__split-link {
  margin-top: 5px;
}
@media (min-width: 720px) {
  .footer__desktop {
    display: flex;
  }
  .footer__mobile {
    display: none;
  }
  .footer__top {
    display: flex;
  }
  .footer__row:not(:first-child) {
    margin-top: 12px;
  }
  .footer__row--flex {
    flex: 1;
  }
  .footer__row--flex:not(:first-child) {
    padding-left: 120px;
  }
  .footer__title {
    margin-bottom: 12px;
  }
  .footer__divider-container {
    padding: 0;
    max-width: 100vw;
  }
  .footer__split-link-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex: 1;
  }
  .footer__split-links {
    flex-direction: row;
  }
  .footer__split-link {
    margin-top: 0;
  }
  .footer__split-link:not(:first-child) {
    margin-left: 24px;
  }
  .footer__bottom {
    display: flex;
    align-items: center;
  }
  .footer__social-links {
    margin: 0;
    margin-top: 1rem;
  }
}

.highlights {
  width: 100%;
}
.highlights__items {
  margin-top: 24px;
}
.highlights__item:not(:first-child) {
  margin-top: 24px;
}
.highlights__label {
  margin-bottom: 10px;
}
@media (min-width: 960px) {
  .highlights__items {
    display: flex;
    margin-top: 32px;
  }
  .highlights__item {
    flex: 1;
  }
  .highlights__item:not(:first-child) {
    margin-top: 0;
    margin-left: 36px;
    border-left: solid 1px #b4b4b4;
    padding-left: 36px;
  }
  .highlights--no-borders .highlights__item:not(:first-child) {
    border: none;
  }
}

.homepage-inquiry {
  height: 100%;
  color: #ffffff;
  position: relative;
}
.homepage-inquiry--search-view .homepage-inquiry__top-text,
.homepage-inquiry--search-view .homepage-inquiry__result,
.homepage-inquiry--search-view .homepage-inquiry__search-text {
  display: none;
}
.homepage-inquiry--search-view .homepage-inquiry__select-width-wrapper {
  position: static;
}
.homepage-inquiry--search-view .homepage-inquiry__select {
  font-size: 18px;
  padding-top: 6px;
}
.homepage-inquiry--search-view .homepage-inquiry__mobile-search-text {
  display: inline-block;
}
.homepage-inquiry--search-view .homepage-inquiry__minimal-dropdown {
  width: auto;
  display: inline;
}
.homepage-inquiry__intro {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s ease;
  padding-bottom: 18px;
}
.homepage-inquiry__padding-wrapper {
  padding: 18px 18px;
  height: 92%;
}
.homepage-inquiry__top-text--desktop {
  display: none;
}
.homepage-inquiry__mobile-search-text {
  display: none;
}
.homepage-inquiry__mobile-search-text::after {
  content: " ";
  white-space: pre;
}
.homepage-inquiry__select {
  position: absolute;
  width: 100%;
  top: 50%;
  transition: top 0.5s ease;
  transition: top 0.3s ease, opacity 0.4s ease-out, width 0.3s ease-out, font-size 0.2s ease;
}
.homepage-inquiry__select:focus {
  outline: none;
}
.homepage-inquiry__select-width-wrapper {
  position: relative;
  margin-bottom: 3rem;
}
.homepage-inquiry--search-view .homepage-inquiry__select {
  top: 0;
}
.homepage-inquiry__option {
  white-space: nowrap;
}
.homepage-inquiry__top {
  position: absolute;
  bottom: 50%;
  padding-bottom: 5rem;
}
.homepage-inquiry__result {
  display: block;
  margin: 0.5em 0;
  transition: color 0.35s ease-out;
}
.homepage-inquiry__result:hover {
  color: #f9b36a;
}
.homepage-inquiry__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
  background-color: #1e283c;
}
.homepage-inquiry__footer--initialized {
  display: block;
}
@media (max-width: 970px) {
  .homepage-inquiry__footer--initialized {
    height: 15%;
  }
}
@media (max-height: 820px) {
  .homepage-inquiry__footer--initialized {
    height: 15%;
  }
}
.homepage-inquiry__footer-links {
  list-style-type: none;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.homepage-inquiry__footer-links--right {
  margin-top: 5px;
}
.homepage-inquiry__footer-link-item {
  color: #ffffff;
  margin-right: 30px;
  margin-top: 5px;
}
.homepage-inquiry__footer-link-item:last-child {
  margin-right: 0;
}
.homepage-inquiry__footer-link-item__text {
  opacity: 0.5;
  line-height: 1;
}
.homepage-inquiry__footer-link-item__link {
  padding-bottom: 1px;
  border-bottom: 0.5px solid #ffffff;
  transition: color, opacity, border-color 150ms ease-in-out;
  display: inline;
  opacity: 0.5;
}
@media (min-width: 720px) {
  .homepage-inquiry__top-text--desktop {
    display: block;
  }
  .homepage-inquiry__top-text--mobile {
    display: none;
  }
}
@media (min-width: 960px) {
  .homepage-inquiry__footer {
    flex-direction: row;
  }
  .homepage-inquiry__footer-links {
    width: 50%;
  }
  .homepage-inquiry__footer-links--right {
    justify-content: flex-end;
    margin-top: 0;
  }
  .homepage-inquiry__footer-link-item__link:hover:not(.link--active), .homepage-inquiry__footer-link-item__link:focus:not(.link--active) {
    opacity: 1;
  }
  .homepage-inquiry--search-view .homepage-inquiry__top-text,
  .homepage-inquiry--search-view .homepage-inquiry__result,
  .homepage-inquiry--search-view .homepage-inquiry__search-text {
    display: block;
  }
  .homepage-inquiry__intro {
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    align-content: flex-end;
    flex-wrap: wrap;
    height: 38%;
    padding-right: 33%;
  }
}
@media (min-width: 960px) and (max-height: 820px) {
  .homepage-inquiry__intro {
    height: 50%;
  }
}
@media (min-width: 960px) {
  .homepage-inquiry__padding-wrapper {
    padding: 0 5vw;
    height: 100%;
  }
  .homepage-inquiry__top {
    position: relative;
    bottom: auto;
    padding-bottom: 0;
    width: 100%;
    transform: translateX(-40px);
    opacity: 0;
    transition: transform 0.4s ease-out, opacity 0.3s ease-out;
  }
  .homepage-inquiry--initial-animation .homepage-inquiry__top {
    opacity: 1;
    transform: none;
  }
  .homepage-inquiry__select {
    width: 10px;
    position: relative;
    top: auto;
    opacity: 0;
    transition-delay: 0.25s;
    font-size: 50px !important;
    line-height: 57px !important;
  }
  .homepage-inquiry__select .minimal-dropdown::after {
    font-size: 0.6em;
    top: 0.5em;
  }
  .homepage-inquiry--initial-animation .homepage-inquiry__select .minimal-dropdown::after {
    animation: homepageArrowFadeIn 0.7s;
  }
  .homepage-inquiry--initial-animation .homepage-inquiry__select {
    width: 500px;
    opacity: 1;
  }
  .homepage-inquiry__mobile-search-text {
    display: none !important;
  }
  .homepage-inquiry__select-width-wrapper {
    width: 500px;
    margin-bottom: 0rem;
    margin-top: 3rem;
  }
  .homepage-inquiry__search-text {
    margin-right: 18px;
    transform: translateX(-40px);
    opacity: 0;
    transition: transform 0.4s ease-out, opacity 0.3s ease-out;
  }
  .homepage-inquiry--initial-animation .homepage-inquiry__search-text {
    opacity: 1;
    transform: none;
  }
}
@media (max-height: 750px) and (max-width: 480px) {
  .homepage-inquiry__padding-wrapper {
    overflow: auto;
  }
}
@media (max-width: 480px) {
  .homepage-inquiry__footer-link-item {
    margin-right: 15px;
  }
  .homepage-inquiry__footer-link-item:last-child {
    margin-right: 0;
  }
}

.homepage-inquiry-panel {
  position: absolute;
  transition: top 0.35s ease-out;
  opacity: 0;
  left: 0;
  top: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 0;
}
.homepage-inquiry-panel--blue {
  background-color: #156289;
}
.homepage-inquiry-panel.active {
  top: 78px;
  bottom: 0;
  opacity: 1;
  height: 100%;
  height: calc(100% - 78px);
  z-index: 10;
}
.homepage-inquiry-panel.active.dropdown {
  top: 188px;
  bottom: 0;
  opacity: 1;
  height: 100%;
  height: calc(100% - 188px);
}
.homepage-inquiry-panel__top {
  padding: 1em 16px;
  background-color: #156289;
  color: #ffffff;
}
.homepage-inquiry-panel__scrollable {
  padding: 0 16px 1em 16px;
  position: relative;
  background-color: #ffffff;
  flex-shrink: 3;
  overflow-x: none;
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.homepage-inquiry-panel__scrollable::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}
.homepage-inquiry-panel__scrollable--blue {
  background-color: #156289;
}
.homepage-inquiry-panel__close {
  float: right;
}
.homepage-inquiry-panel__people-search, .homepage-inquiry-panel__other-search {
  padding: 0 16px 5px 16px;
  background-color: #ffffff;
}
.homepage-inquiry-panel__people-search input, .homepage-inquiry-panel__other-search input {
  outline: none;
}
@media (min-width: 960px) {
  .homepage-inquiry-panel {
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.35s ease-out;
    overflow: auto;
  }
  .homepage-inquiry-panel.active {
    transform: none;
    top: 0;
    left: auto;
    right: 0;
    height: 100%;
    width: 33%;
  }
  .homepage-inquiry-panel__top {
    padding: 32px 40px;
  }
  .homepage-inquiry-panel__scrollable {
    flex-shrink: 2;
    flex-grow: 1;
    overflow: auto;
    padding-left: 40px;
    padding-right: 40px;
  }
  .homepage-inquiry-panel__people-search, .homepage-inquiry-panel__other-search {
    padding: 0 40px;
    padding-top: 20px;
  }
}

.homepage-inquiry-service-list {
  padding-top: 20px;
  border-top: 1px solid #f9b36a;
}
.homepage-inquiry-service-list:last-child {
  border-bottom: 1px solid #f9b36a;
}
.homepage-inquiry-service-list__heading {
  color: #f9b36a;
  margin: 1em 0;
}
.homepage-inquiry-service-list__item {
  margin: 0.5em 0;
}
.homepage-inquiry-service-list__link {
  transition: opacity 0.5s ease;
  transition: opacity 0.3s ease-in-out;
}
.homepage-inquiry-service-list__link:hover, .homepage-inquiry-service-list__link:focus {
  opacity: 0.5;
}
@media (min-width: 960px) {
  .homepage-inquiry-service-list {
    padding-top: 30px;
    padding-bottom: 45px;
  }
  .homepage-inquiry-service-list__heading {
    margin: 1.5em 0;
  }
  .homepage-inquiry-service-list__item {
    margin: 0 0 1em 0;
  }
  .homepage-inquiry-service-list__list {
    column-count: 2;
  }
}

.insight-card {
  margin-bottom: 48px;
}
.insight-card__bar {
  height: 4px;
  width: 100%;
  background: #7D7E81;
  margin-bottom: 30px;
}
.insight-card__bar--Publication {
  background: #3da3a3;
}
.insight-card__bar--Blog {
  background: #e06565;
}
.insight-card__bar--Advisory {
  background: #7D7E81;
}
.insight-card__bar--News {
  background: #039ecc;
}
.insight-card__bar--Presentations, .insight-card__bar--news {
  background: #156289;
}
.insight-card__image {
  height: 0;
  width: 100%;
  padding-top: 67%;
  background-size: cover;
  background-position: center;
  margin-bottom: 24px;
}
.insight-card__type {
  color: #7D7E81;
}
.insight-card__title {
  margin-top: 20px;
  padding: 0.3em 0;
  transition: transform 0.35s ease-out;
  display: block;
}
.insight-card__more {
  margin-top: 20px;
}
@media (min-width: 720px) {
  .insight-card {
    padding: 5px;
  }
}
.insight-card.theme-medium-blue .insight-card__type {
  color: #156289;
}
.insight-card.theme-coral .insight-card__type {
  color: #e06565;
}
.insight-card.theme-cyan .insight-card__type {
  color: #039ecc;
}
.insight-card.theme-teal .insight-card__type {
  color: #3da3a3;
}
.insight-card.theme-yellow .insight-card__type {
  color: #f9b36a;
}

@media (min-width: 720px) {
  .insight-card-container {
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .insight-card-container .insight-card {
    width: 50%;
  }
}
@media (min-width: 960px) {
  .insight-card-container .insight-card {
    width: 33%;
  }
}

.insight-listing {
  color: #000000;
  margin-top: 1.7em;
}
.insight-listing__title {
  color: #0c5273;
  transition: color 0.35s ease-out;
}
.insight-listing__title:hover {
  color: #000000;
}
.insight-listing__link {
  margin-bottom: 5px;
}

span.insight-listing__title {
  color: #0c5273;
  transition: color 0.35s ease-out;
}
span.insight-listing__title:hover {
  color: #000000;
}

.insights-search {
  width: 100%;
}
.insights-search__filters {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.insights-search__more__button {
  font-weight: 500;
}
@media (min-width: 720px) {
  .insights-search__filters {
    flex-direction: row;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: #156289;
  color: #ffffff;
  transition: transform 0.6s ease-in-out;
}
.menu--navbar-above {
  padding-top: 66px;
  transform: translateY(-100%);
}
.menu--navbar-below {
  padding-bottom: 66px;
  transform: translateY(100%);
}
.menu--visible {
  transform: none;
}
.menu__content {
  box-sizing: border-box;
  padding: 40px 20px;
  padding-top: 120px;
  height: 100%;
  overflow: auto;
}
.menu__links {
  display: flex;
  flex-direction: column;
}
.menu__links--secondary {
  margin-top: 36px;
}
.menu__link-item:not(:first-child) {
  margin-top: 14px;
}
.menu__link-item--primary {
  padding-bottom: 12px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}
.menu__link {
  transition: opacity 0.3s ease-in-out;
}
.menu__link:hover, .menu__link:focus {
  opacity: 0.5;
}
.menu__search {
  margin-top: 64px;
}
@media (min-width: 960px) {
  .menu {
    display: none;
  }
}

.navbar {
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 20px 0;
  padding-top: 0;
}
.navbar--fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  transition: top 1s;
}
.navbar--home {
  position: relative;
}
.navbar__hidden {
  display: none;
}
.navbar--hide {
  top: -66px;
  transition: top 1s;
}
.navbar--fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5;
  box-shadow: 0px 0px 16px -3px #000000;
}
.navbar__page {
  padding-top: 66px;
}
.navbar__page-bottom {
  height: 100%;
  top: auto;
  height: calc(100% - 71px);
}
.navbar__container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  padding-top: 1.25rem;
}
.navbar__left, .navbar__right {
  flex: 1;
}
.navbar__right {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.navbar__brand {
  width: 121px;
  transition: opacity 0.3s ease-in-out;
}
.navbar__brand:hover, .navbar__brand:focus {
  opacity: 0.5;
}
.navbar__brand img {
  width: 100%;
  transform: scale(1.4);
  margin-left: 29px;
}
.navbar__links {
  display: none;
  position: relative;
  margin-right: 50px;
}
.navbar__link {
  margin-left: 44px;
}
.navbar__link:hover {
  color: #0c5273;
}
.navbar__link--primary:first-child {
  margin-left: 0;
}
.navbar__link--active {
  color: #0c5273;
}
.navbar__icon {
  font-size: 18px;
}
.navbar__icon__search {
  position: relative;
  z-index: 9;
}
.navbar__menu {
  transition: opacity 0.3s ease-in-out;
}
.navbar__menu:hover, .navbar__menu:focus {
  opacity: 0.5;
}
@media (min-width: 960px) {
  .navbar__menu {
    display: none;
  }
  .navbar__links {
    display: flex;
    align-items: center;
  }
}

.navbar-search {
  display: none;
  z-index: 8;
  width: 25px;
  position: absolute;
  right: 0px;
  overflow: hidden;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.4s;
}
.navbar-search__container {
  position: relative;
  display: flex;
  background: #ffffff;
}
.navbar-search__container:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #3da3a3;
  transition: width 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s;
}
.navbar-search__container .navbar__link--secondary {
  flex-shrink: 0;
}
.navbar-search--open {
  width: 300px;
}
.navbar-search--open .searchbar__input, .navbar-search--open .icon__close {
  visibility: visible;
}
.navbar-search--open .navbar__icon__search {
  color: #3da3a3;
}
.navbar-search--open .navbar-search__container:after {
  width: 100%;
}
@media (min-width: 960px) {
  .navbar-search {
    display: block;
  }
}

header.navbar {
  height: auto;
}

.font__h1, .page-hero__text--alt, .page-hero__left__office__title, .page-hero__title, .page-hero__event__title h1, .page-hero__event__title, .menu__link--primary {
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0px;
}
.font__h2, .modal__inner h2.modal__inner__header, .global-search__search__clear, .global-search__search, .subscribe__header, .blog-slide__left__header, .office-card__title, .component-container__header, .tombstone-container__header, .team-landing__header__label, .contacts__label, .contact__left__header, .related-practices__left__header, .events__event__header, .events__header, .cta-component-image__text__header, .cta-component__text__header, .text-carousel__left__menu__header, .text-carousel__left__menu__item, .text-carousel__left__header, .rte-list-alternate__title, .rich-text h2,
.rich-text--smaller h2, .related-articles__header, .card-component__header, .page-hero__text--content, .bio-hero__name, .experience__title, .expertise-item__header--active, .expertise-item__header--inactive, .highlights__title, .homepage-inquiry__search-text, .font__h2--desktop, .homepage-inquiry__select {
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 3rem;
  letter-spacing: -0.3px;
}
.font__h2--extralight, .global-search__search__clear, .global-search__search, .expertise-item__header--inactive, .homepage-inquiry__search-text, .homepage-inquiry__select, .font__h2--desktop--extralight {
  font-weight: 200;
  letter-spacing: 0px;
}
.font__h2--extrasmall, .font__h2--desktop--extrasmall {
  font-size: 20px;
}
.font__subhead1, .people-search__search__input::placeholder, .people-search__search__input, .style-guide__section-title, .rich-text--blue, .rich-text .rich-text--blue,
.rich-text--smaller--blue,
.rich-text .rich-text--smaller--blue,
.rich-text--smaller .rich-text--blue,
.rich-text--smaller .rich-text--smaller--blue, .rich-text h3,
.rich-text--smaller h3, .regular-input__search__input::placeholder, .regular-input__search__input, .page-hero__title--light, .homepage-inquiry__option, .homepage-inquiry-panel__people-search, .homepage-inquiry-panel__other-search {
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.4px;
}
.font__subhead1--extralight, .people-search__search__input::placeholder, .people-search__search__input, .regular-input__search__input::placeholder, .regular-input__search__input, .page-hero__title--light, .homepage-inquiry__option, .homepage-inquiry-panel__people-search, .homepage-inquiry-panel__other-search {
  font-weight: 200;
}
.font__subhead2, .people-search__header, .subscribe__form__input__label, .subscribe__form__input__invalid-message, .event-detail__hosted, .component-card__date, .component-card__type, .contact-card__title, .events__event__hosted, .rte-list__title, .rich-text table th,
.rich-text--smaller table th, .page-hero__content__eyebrow, .live-search-results__header-text, .bio-hero__level, .button, .rich-text .button,
.rich-text--smaller .button, .rich-text a.button,
.rich-text a .button,
.rich-text--smaller a.button,
.rich-text--smaller a .button, .expertise-title, .footer__title, .homepage-inquiry-service-list__heading, .insight-card__type, .insight-card__date {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
.font__subhead2--regular {
  font-weight: 400;
}
.font__subhead3, .page-hero__title--alt, .homepage-inquiry__top-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
.font__body, .modal, .global-search__results__found, .people-search__people__no-results, .subscribe__form__topics__add, .subscribe__form__result-message.rich-text h2, .subscribe__form__result-message, .subscribe__description, .event-detail__related-practices__practice, .event-detail__related-practices__header, .event-detail__header, .link-list-component__list__link a, .component-container__body, .component-container__subheader p, .component-container__subheader, .component-card__title, .tombstone-grid-item__text__header, .tombstone-item__text__header, .tombstone-container__subheader, .team-landing__header__abstract, .contact-card__name, .contacts__abstract, .contact__right__text, .contact h3,
.contact h4,
.contact h5,
.contact h6,
.contact p, .related-practices__right__practice__header, .events__event__description, .events__event__details__location, .events__event__cta, .events__description, .cta-component-image__text__body p, .cta-component-image__text__body, .cta-component__text__body p, .cta-component__text__body, .text-carousel__right__container p, .text-carousel__right__container, .rte-list-alternate__label, .rte-list-condensed p, .rich-text h4,
.rich-text--smaller h4, .rich-text, .page-hero__text p, .page-hero__text a, .page-hero__text li, .page-hero__text span, .page-hero__text, .page-hero__event__location, .page-hero__authors, .link-list__title, .link-list__header, .live-search-results__no-results, blockquote, .cta-component__text__body blockquote, .rich-text blockquote,
.rich-text--smaller blockquote, .bio-hero__quote, .experience__label, .expertise__side-nav__container__header, .highlights__label, .insight-card__title, .insights-search__insights__no-results, .menu__link--secondary {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0px;
}
.font__body--semibold, .global-search__results__found__result__bold, .people-search__people__no-results, .topic__close, .event-detail__related-practices__practice, .event-detail__header, .link-list-component__list__link a, .link-list-component__list__link, .component-card__title, .tombstone-grid-item__text__header, .tombstone-item__text__header, .contact-card__name, .related-practices__right__practice__header, .events__event__cta, .rte-list-alternate__label, .page-hero__authors__author, .link-list__links__link__label--bold, .link-list__title, .link-list__header, .live-search-results__no-results, .experience__label, .expertise__side-nav__container__header, .highlights__label, .insight-card__title, .insights-search__insights__no-results {
  font-weight: 600;
}
.font__body--extralight, .page-hero__text__subheader, .page-hero__subtitle, .page-hero__authors {
  font-weight: 200;
}
.font__body2, .sitesearch-listing__title, .sitesearch-listing, .topic__close, .topic, .subscribe__form__topics__list__header, .tombstone-item__text__body a, .contact-card__text, .contact-card__subtitle, .related-practices__right__practice__body, .events__event__details__date, .events__event__date, .video__container__caption, .tabs__container__menu__tab h2, .tabs__container__menu__tab, .service-listing__title, .service-listing, .rte-list-alternate__content, .rte-list__content, .rich-text table td,
.rich-text--smaller table td, .rich-text h5,
.rich-text--smaller h5, .rich-text--smaller, .regular-dropdown__select, .regular-input__datepicker, .regular-input__input, .regular-input, .professional-listing__name, .professional-listing, .page-hero__title__practice__breadcrumb, .page-hero__title__practice, .page-hero__date, .link-list__body p, .link-list__body, .live-search-results__side-scroll, .bio-hero__title, .bio-hero__prefix, .bio-hero__primary, .bio-hero__secondary, .bio-hero__pronoun, .experience__content, .expertise-accordion-item__header--inactive, .footer__faded, .footer__caption, .highlights__content, .insight-listing, span.insight-listing__title, .navbar__link--primary {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0px;
}
.font__body2--semibold, .sitesearch-listing__name, .office-contact__fax__label, .office-contact__telephone__label, .office-contact__address__icon:before, .tombstone-item__text__body a, .events__event__details__date, .events__event__date, .tabs__container__menu__tab h2, .tabs__container__menu__tab, .service-listing__name, .professional-listing__name, .bio-hero__prefix, .expertise-accordion-item__header--active, .homepage-inquiry-panel__top, .insight-listing__title, span.insight-listing__title, .navbar__link--primary {
  font-weight: 600;
}
.font__body2--extralight, .footer__faded {
  letter-spacing: 0.5px;
  font-weight: 200;
}
.font__body3, .interactive-maps__tab, .event-detail__content, .component-card__eyebrow, .component-card, .rich-text h6,
.rich-text--smaller h6, .live-search-results__see-all, .link, .homepage-inquiry-service-list__item, .homepage-inquiry-service-list__link, .insight-card, .navbar__link--secondary {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}
.font__body3--semi-bold, .link--active {
  font-weight: 600;
}
.font__body3--extralight {
  font-weight: 200;
}
.font__body4, .sitesearch-listing__sub, .text-carousel__right__image-container__caption, .service-listing__sub, .rich-text__image-caption span, .rich-text__video-caption span,
.rich-text--smaller__image-caption span,
.rich-text--smaller__video-caption span, .rich-text .caption-text,
.rich-text--smaller .caption-text, .professional-listing__item, .professional-listing__sub, .link--body4, .footer__form .searchbar__placeholder, .footer__copyright, .homepage-inquiry__footer-link-item {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.75px;
}
.font__body4--extralight, .footer__copyright, .homepage-inquiry__footer-link-item__text {
  font-weight: 200;
}
.font__subhead3, .page-hero__title--alt, .homepage-inquiry__top-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
@media (min-width: 720px) {
  .font__h1, .page-hero__text--alt, .page-hero__left__office__title, .page-hero__title, .page-hero__event__title h1, .page-hero__event__title, .menu__link--primary {
    font-size: 84px;
    line-height: 84px;
    letter-spacing: -1px;
  }
  .font__h2, .modal__inner h2.modal__inner__header, .global-search__search__clear, .global-search__search, .subscribe__header, .blog-slide__left__header, .office-card__title, .component-container__header, .tombstone-container__header, .team-landing__header__label, .contacts__label, .contact__left__header, .related-practices__left__header, .events__event__header, .events__header, .cta-component-image__text__header, .cta-component__text__header, .text-carousel__left__menu__header, .text-carousel__left__menu__item, .text-carousel__left__header, .rte-list-alternate__title, .rich-text h2,
  .rich-text--smaller h2, .related-articles__header, .card-component__header, .page-hero__text--content, .bio-hero__name, .experience__title, .expertise-item__header--active, .expertise-item__header--inactive, .highlights__title, .homepage-inquiry__search-text {
    font-size: 50px;
    line-height: 57px;
    letter-spacing: -1px;
  }
  .font__h2--extralight, .global-search__search__clear, .global-search__search, .expertise-item__header--inactive, .homepage-inquiry__search-text, .homepage-inquiry__select {
    letter-spacing: 0px;
  }
  .font__subhead1, .people-search__search__input::placeholder, .people-search__search__input, .style-guide__section-title, .rich-text--blue, .rich-text .rich-text--blue,
  .rich-text--smaller--blue,
  .rich-text .rich-text--smaller--blue,
  .rich-text--smaller .rich-text--blue,
  .rich-text--smaller .rich-text--smaller--blue, .rich-text h3,
  .rich-text--smaller h3, .regular-input__search__input::placeholder, .regular-input__search__input, .page-hero__title--light, .homepage-inquiry__option, .homepage-inquiry-panel__people-search, .homepage-inquiry-panel__other-search {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.4px;
  }
  .font__subhead1--extralight, .people-search__search__input::placeholder, .people-search__search__input, .regular-input__search__input::placeholder, .regular-input__search__input, .page-hero__title--light, .homepage-inquiry__option, .homepage-inquiry-panel__people-search, .homepage-inquiry-panel__other-search {
    font-weight: 200;
  }
  .font__subhead2, .people-search__header, .subscribe__form__input__label, .subscribe__form__input__invalid-message, .event-detail__hosted, .component-card__date, .component-card__type, .contact-card__title, .events__event__hosted, .rte-list__title, .rich-text table th,
  .rich-text--smaller table th, .page-hero__content__eyebrow, .live-search-results__header-text, .bio-hero__level, .button, .rich-text .button,
  .rich-text--smaller .button, .rich-text a.button,
  .rich-text a .button,
  .rich-text--smaller a.button,
  .rich-text--smaller a .button, .expertise-title, .footer__title, .homepage-inquiry-service-list__heading, .insight-card__type, .insight-card__date {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
  }
  .font__body, .modal, .global-search__results__found, .people-search__people__no-results, .subscribe__form__topics__add, .subscribe__form__result-message.rich-text h2, .subscribe__form__result-message, .subscribe__description, .event-detail__related-practices__practice, .event-detail__related-practices__header, .event-detail__header, .link-list-component__list__link a, .component-container__body, .component-container__subheader p, .component-container__subheader, .component-card__title, .tombstone-grid-item__text__header, .tombstone-item__text__header, .tombstone-container__subheader, .team-landing__header__abstract, .contact-card__name, .contacts__abstract, .contact__right__text, .contact h3,
  .contact h4,
  .contact h5,
  .contact h6,
  .contact p, .related-practices__right__practice__header, .events__event__description, .events__event__details__location, .events__event__cta, .events__description, .cta-component-image__text__body p, .cta-component-image__text__body, .cta-component__text__body p, .cta-component__text__body, .text-carousel__right__container p, .text-carousel__right__container, .rte-list-alternate__label, .rte-list-condensed p, .rich-text h4,
  .rich-text--smaller h4, .rich-text, .page-hero__text p, .page-hero__text a, .page-hero__text li, .page-hero__text span, .page-hero__text, .page-hero__event__location, .page-hero__authors, .link-list__title, .link-list__header, .live-search-results__no-results, blockquote, .cta-component__text__body blockquote, .rich-text blockquote,
  .rich-text--smaller blockquote, .bio-hero__quote, .experience__label, .expertise__side-nav__container__header, .highlights__label, .insight-card__title, .insights-search__insights__no-results, .menu__link--secondary {
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    letter-spacing: -0.4px;
  }
  .font__body--semibold, .global-search__results__found__result__bold, .people-search__people__no-results, .topic__close, .event-detail__related-practices__practice, .event-detail__header, .link-list-component__list__link a, .link-list-component__list__link, .component-card__title, .tombstone-grid-item__text__header, .tombstone-item__text__header, .contact-card__name, .related-practices__right__practice__header, .events__event__cta, .rte-list-alternate__label, .page-hero__authors__author, .link-list__links__link__label--bold, .link-list__title, .link-list__header, .live-search-results__no-results, .experience__label, .expertise__side-nav__container__header, .highlights__label, .insight-card__title, .insights-search__insights__no-results {
    font-weight: 600;
  }
  .font__subhead3, .page-hero__title--alt, .homepage-inquiry__top-text {
    font-size: 18px;
    line-height: 23px;
  }
}
@media (min-width: 960px) {
  .font__h2--desktop, .homepage-inquiry__select {
    font-size: 50px;
    line-height: 57px;
    letter-spacing: -1px;
  }
  .font__h2--desktop--extralight {
    letter-spacing: 0px;
  }
}

blockquote, .cta-component__text__body blockquote, .rich-text blockquote,
.rich-text--smaller blockquote {
  position: relative;
}
blockquote:before, .cta-component__text__body blockquote:before, .rich-text blockquote:before,
.rich-text--smaller blockquote:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
  font-size: 30px;
  color: #e06565;
  display: block;
  margin-bottom: 25px;
}
blockquote p:first-child {
  display: inline;
}

*:focus {
  outline: none;
}

.live-search-results {
  color: #000000;
}
.live-search-results__side-scroll-wrapper {
  background-color: #ffffff;
  padding: 16px;
}
.live-search-results__side-scroll {
  overflow: auto;
  white-space: nowrap;
}
.live-search-results__side-scroll:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  height: 1em;
  margin-top: -1em;
  width: 7em;
  background: linear-gradient(transparent, #FFF) left repeat;
  background: linear-gradient(to right, transparent, rgb(255, 255, 255) 100%);
  pointer-events: none;
}
.live-search-results__side-scroll-item {
  margin-right: 2em;
  color: #7D7E81;
  display: inline-block;
}
.live-search-results__side-scroll-item.active {
  color: #000000;
}
.live-search-results__section {
  margin-bottom: 2em;
}
.live-search-results__see-all {
  color: #000000;
  position: relative;
}
.live-search-results__see-all:focus {
  outline: none;
}
.live-search-results__see-all::after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 0;
  right: 0;
  border-bottom: solid 1px;
}
.live-search-results__header {
  color: #318c89;
  padding: 1em 0;
  border-bottom: 1px solid #318c89;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.live-search-results__header--no-see-all {
  flex-direction: row;
}
.live-search-results__header--cyan {
  color: #048db6;
  border-color: #048db6;
}
.live-search-results__header--blue {
  color: #0c5273;
  border-color: #0c5273;
}
.live-search-results__header--orange {
  color: #c95555;
  border-color: #c95555;
}
.live-search-results__no-results {
  margin-top: 24px;
  color: #000000;
}
@media (min-width: 960px) {
  .live-search-results__side-scroll:after {
    display: none;
  }
  .live-search-results__side-scroll-wrapper {
    padding: 20px 40px;
  }
}

.link-list:not(:first-child) {
  margin-top: 24px;
}
.link-list__header + .link {
  margin-top: 5px;
}
.link-list__body {
  margin-top: 5px;
  width: 100%;
}
.link-list__body + .link {
  margin-top: 5px;
}
.link-list__title--coral {
  color: #e06565;
}
.link-list__links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.link-list__links .long-link:after {
  content: none;
}
.link-list__links__link__label {
  border-bottom: 1px solid;
}
.link-list__links__link__label--bold {
  font-size: 15px;
}
.link-list__title:not(:first-child), .link-list__links:not(:first-child) {
  margin-top: 4px;
}

.minimal-dropdown {
  border-bottom: 1px solid;
  border-color: inherit;
  width: 100%;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  padding-right: 1em;
}
.minimal-dropdown::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e912";
  transition: transform 0.35s ease-out;
  font-size: 0.8em;
  position: absolute;
  right: 0;
  top: 0.2em;
}
.minimal-dropdown--no-arrow::after {
  visibility: hidden;
}
.minimal-dropdown--up-arrow::after {
  transform: rotate(-180deg);
}
.minimal-dropdown__options {
  padding-top: 0.3em;
  position: absolute;
  top: 100%;
  visibility: hidden;
  width: 100%;
}
.minimal-dropdown__options.active {
  visibility: visible;
  animation: 0.5s minimalDropdownFadeIn;
}
.minimal-dropdown__option {
  width: 100%;
  margin-top: 0.3em;
  display: block;
  transition: color 0.35s ease-out;
}
.minimal-dropdown__option:hover {
  color: #f9b36a;
}
.minimal-input {
  padding-top: 0.4em;
  padding-bottom: 0.2em;
  border-bottom: 1px solid;
  border-color: inherit;
  width: 100%;
  background-color: transparent;
  position: relative;
}
.minimal-input--teal-dark {
  color: #318c89;
}
.minimal-input--teal-dark .minimal-input__input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #318c89;
}
.minimal-input--teal-dark .minimal-input__input::-moz-placeholder {
  color: #318c89;
}
.minimal-input--teal-dark .minimal-input__input::-webkit-input-placeholder {
  color: #318c89;
}
.minimal-input--teal-dark .minimal-input__input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #318c89;
}
.minimal-input--teal-dark .minimal-input__input::-ms-input-placeholder { /* Microsoft Edge */
  color: #318c89;
}
.minimal-input__placeholder {
  position: absolute;
  left: 0;
  top: 50%;
  pointer-events: none;
  transform: translateY(-50%);
}
.minimal-input__input {
  height: 1.5em;
  width: 100%;
}

.page-hero {
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #1e283c;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  position: relative;
}
.page-hero--pattern {
  background-size: auto;
}
.page-hero--dark-theme {
  color: #000000;
}
.page-hero__hidden-title {
  visibility: hidden;
}
.page-hero__authors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.page-hero__authors__author {
  line-height: inherit;
  margin-right: 6px;
}
.page-hero__authors__author a {
  border-bottom: solid 1px;
  line-height: 1.1;
}
.page-hero--coral, .page-hero--blog {
  background-color: #e06565;
}
.page-hero--teal, .page-hero--publication {
  background: #3da3a3;
}
.page-hero--gray, .page-hero--advisory {
  background: #7D7E81;
}
.page-hero--cyan, .page-hero--News {
  background: #039ecc;
}
.page-hero--blue, .page-hero--presentation {
  background: #156289;
}
.page-hero--white {
  background: #ffffff;
  border-top: 1px solid #b4b4b4;
  border-bottom: 1px solid #b4b4b4;
}
.page-hero.color__navy {
  background-color: #1e283c;
}
.page-hero.color__navy--dark {
  background-color: #0b1a2b;
}
.page-hero.color__blue {
  background-color: #156289;
}
.page-hero.color__blue--dark {
  background-color: #0c5273;
}
.page-hero.color__cyan {
  background-color: #039ecc;
}
.page-hero.color__cyan--dark {
  background-color: #048db6;
}
.page-hero.color__teal {
  background-color: #3da3a3;
}
.page-hero.color__teal-dark {
  background-color: #318c89;
}
.page-hero.color__coral {
  background-color: #e06565;
}
.page-hero.color__coral--dark {
  background-color: #c95555;
}
.page-hero.color__yellow {
  background-color: #f9b36a;
}
.page-hero.color__yellow--dark {
  background-color: #f2985a;
}
.page-hero.color__gray {
  background-color: #7D7E81;
}
.page-hero.color__gray--dark {
  background-color: #7d7e81;
}
.page-hero__video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}
.page-hero__event {
  color: #000000;
}
.page-hero__event .page-hero__event__left {
  width: 100%;
}
.page-hero__event__title {
  margin-top: 24px;
  color: #156289;
}
.page-hero__event__location {
  margin-bottom: 24px;
}
.page-hero__container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  position: relative;
  display: flex;
}
.page-hero__container--line {
  width: 100%;
  flex-direction: column-reverse;
}
@media (min-width: 720px) {
  .page-hero__title {
    line-height: 96px;
  }
}
@media (max-width: 720px) {
  .page-hero__title {
    line-height: 42px;
  }
}
.page-hero__title__practice {
  font-weight: 600;
}
.page-hero__title__practice__breadcrumb {
  font-weight: 400;
}
.page-hero__subtitle {
  margin-top: 10px;
}
.page-hero__center {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 72px;
  padding-bottom: 72px;
}
.page-hero__right {
  align-self: flex-end;
  width: 45%;
  margin-right: 30px;
}
.page-hero__right__img {
  display: flex;
}
.page-hero__right__img img {
  width: 100%;
  margin: 0 auto;
}
.page-hero__left {
  margin-left: 0;
}
@media (min-width: 720px) {
  .page-hero__left {
    min-height: 520px;
  }
}
.page-hero__left__office__address {
  margin-top: 24px;
}
.page-hero__left {
  padding-top: 72px;
  padding-bottom: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.page-hero__text {
  margin-top: 32px;
}
.page-hero__text__subheader {
  margin-bottom: 24px;
}
.page-hero__text__social {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.page-hero__text__social--bottom {
  margin-bottom: 0;
  margin-top: 24px;
}
.page-hero__text__social__icon {
  margin-right: 20px;
  transition: opacity 0.35s ease-out;
}
.page-hero__text__social__icon:hover {
  opacity: 0.5;
  transition: opacity 0.35s ease-out;
}
.page-hero__text__social__icon:before {
  font-size: 20px;
}
.page-hero__text--alt {
  margin-bottom: 24px;
}
.page-hero__text--alt:only-child {
  margin-bottom: 0;
}
.page-hero__cta {
  margin-top: 32px;
}
.page-hero__gradient {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(270deg, rgba(30, 40, 60, 0) 0%, #000000 100%);
}
.page-hero__gradient--content {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.45) 100%, rgba(0, 0, 0, 0) 75%);
}
@media (min-width: 480px) {
  .page-hero__gradient--content {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.45) 25%, rgba(0, 0, 0, 0) 75%);
  }
}
.page-hero__gradient--center {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
@media (min-width: 480px) {
  .page-hero__container--line {
    flex-direction: row;
  }
  .page-hero__left {
    width: 65%;
  }
  .page-hero__right {
    align-self: center;
  }
  .page-hero__center, .page-hero__left {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
@media (min-width: 720px) {
  .page-hero {
    min-height: 520px;
  }
  .page-hero__right {
    display: inline-block;
  }
}
@media (min-width: 960px) {
  .page-hero__center, .page-hero__left, .page-hero__right {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .page-hero__left, .page-hero__right {
    width: 70%;
  }
  .page-hero__left {
    padding-left: 0;
  }
  .page-hero__left__office__address {
    margin-top: 48px;
  }
  .page-hero__text {
    margin-top: 18px;
  }
  .page-hero__cta {
    margin-top: 48px;
  }
}

.video-hero {
  overflow: hidden;
  position: relative;
  height: 660px;
}
.video-hero__video {
  object-fit: cover;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
}
@media (min-width: 1440px) {
  .video-hero {
    min-height: 660px;
  }
}

@keyframes animator {
  0% {
    background-position: 200% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.pattern-background {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}
.pattern-background__gradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #42a3a2 0%, #229fbf 10%, #19769c 20%, #1e283a 50%, #19769c 80%, #229fbf 90%, #42a3a2 100%);
  background-size: 200%;
  animation-duration: 5s;
  animation-name: animator;
  animation-iteration-count: 30;
  animation-timing-function: linear;
}
@supports (-ms-ime-align: auto) {
  .pattern-background__gradient {
    animation-duration: 0;
    animation-iteration-count: 0;
  }
}
.pattern-background__pattern {
  width: 100%;
  height: 100%;
  background-image: url("/assets/public/static/images/pattern-background.svg");
}
.pattern-background__mask {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgb(30, 40, 60) 0%, rgba(30, 40, 60, 0.5) 75%);
}

.practice__tabs {
  margin-left: auto;
  margin-right: auto;
}
.practice .section__main {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  width: 100%;
}

.card-component__header {
  margin-bottom: 24px;
}

.professional-listing {
  color: #000000;
  margin-top: 1.7em;
}
.professional-listing__name {
  color: #318c89;
  transition: color 0.35s ease-out;
}
.professional-listing__name:hover {
  color: #000000;
}
.professional-listing__sub {
  display: flex;
  flex-wrap: wrap;
  align-content: middle;
  margin-bottom: 5px;
}
.professional-listing__item {
  padding-right: 5px;
}

.regular-input {
  margin: 16px 0;
  margin-right: 1.5rem;
  position: relative;
  width: 100%;
  outline: 1px solid #D8D8D8;
}
.regular-input__search {
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.regular-input__search__input {
  margin-left: 10px;
}
.regular-input__search__input::placeholder {
  color: #000000;
}
.regular-input__search:focus-within {
  border-color: #3da3a3;
}
.regular-input--active {
  outline-color: #3da3a3;
}
.regular-input--active .icon__close {
  color: #3da3a3;
}
.regular-input::before {
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 16px;
}
.regular-input--search:focus-within {
  outline-color: #3da3a3;
}
.regular-input--search--active:before {
  content: none;
}
.regular-input--search__clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 0.9375rem;
}
.regular-input__clear {
  color: #3da3a3;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.35s ease-out;
  cursor: pointer;
}
.regular-input__clear:hover {
  opacity: 0.5;
  transition: opacity 0.35s ease-out;
}
.regular-input__input {
  padding: 14px 14px;
  color: #000000;
  width: 100%;
}
.regular-input__input:focus {
  outline: none;
}
.regular-input__input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;
}
.regular-input__input::-moz-placeholder {
  color: #000000;
}
.regular-input__input::-webkit-input-placeholder {
  color: #000000;
}
.regular-input__input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000000;
}
.regular-input__input::-ms-input-placeholder { /* Microsoft Edge */
  color: #000000;
}
.regular-input__datepicker {
  padding: 14px;
  color: #000000;
  width: 100%;
  background: transparent;
  transition: background-color 0.35s ease-out;
  position: relative;
}
.regular-input__datepicker input {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}
@media (min-width: 480px) {
  .regular-input {
    width: auto;
  }
}
@media (min-width: 960px) {
  .regular-input {
    display: inline-block;
    width: auto;
  }
  .regular-input::after {
    top: 18px;
  }
  .regular-input__input {
    width: auto;
  }
}

input::-ms-clear {
  display: none;
}

.regular-dropdown {
  margin: 16px 0;
  position: relative;
  outline: 1px solid #D8D8D8;
  overflow: hidden;
  transition: background-color 0.35s ease-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  min-width: 100px;
}
.regular-dropdown--active {
  color: #3da3a3;
  outline-color: #3da3a3;
}
.regular-dropdown__caret {
  pointer-events: none;
}
.regular-dropdown__caret--active {
  color: #318c89;
}
.regular-dropdown__caret--clickable {
  pointer-events: all;
}
.regular-dropdown:focus {
  color: #3da3a3;
}
.regular-dropdown::after {
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 16px;
}
.regular-dropdown__caret {
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 16px;
}
.regular-dropdown__caret.icon__chevron-thin-down {
  z-index: -1;
}
.regular-dropdown--arrow:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e912";
}
.regular-dropdown--x:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e901";
}
.regular-dropdown:hover .regular-dropdown__select {
  color: #3da3a3;
}
.regular-dropdown:hover::after {
  color: #3da3a3;
}
.regular-dropdown__select {
  padding: 14px;
  color: #000000;
  width: 100%;
  background: transparent;
  transition: background-color 0.35s ease-out;
  position: relative;
}
.regular-dropdown__select option {
  color: black;
}
.regular-dropdown__select:focus {
  outline: none;
}
.regular-dropdown__select--active {
  color: #3da3a3;
}
.regular-dropdown__select:hover {
  background-color: transparent;
  transition: background-color 0.35s ease-out;
}
@media (min-width: 480px) {
  .regular-dropdown {
    margin-right: 24px;
  }
}
@media (min-width: 960px) {
  .regular-dropdown {
    width: auto;
  }
  .regular-dropdown::after {
    top: 18px;
  }
  .regular-dropdown__select {
    background-color: transparent;
  }
}

.related-articles .insight-card {
  flex-grow: 1;
}
.related-articles__header {
  margin-bottom: 30px;
}
.related-articles-container {
  flex-wrap: nowrap;
}

.rich-text h2:not(:first-child),
.rich-text h3:not(:first-child),
.rich-text h4:not(:first-child),
.rich-text h5:not(:first-child),
.rich-text h6:not(:first-child),
.rich-text ul:not(:first-child),
.rich-text ol:not(:first-child),
.rich-text blockquote:not(:first-child),
.rich-text p:not(:first-child),
.rich-text--smaller h2:not(:first-child),
.rich-text--smaller h3:not(:first-child),
.rich-text--smaller h4:not(:first-child),
.rich-text--smaller h5:not(:first-child),
.rich-text--smaller h6:not(:first-child),
.rich-text--smaller ul:not(:first-child),
.rich-text--smaller ol:not(:first-child),
.rich-text--smaller blockquote:not(:first-child),
.rich-text--smaller p:not(:first-child) {
  margin-top: 24px;
}
.rich-text h2:first-child,
.rich-text h3:first-child,
.rich-text h4:first-child,
.rich-text h5:first-child,
.rich-text h6:first-child,
.rich-text ul:first-child,
.rich-text ol:first-child,
.rich-text blockquote:first-child,
.rich-text p:first-child,
.rich-text--smaller h2:first-child,
.rich-text--smaller h3:first-child,
.rich-text--smaller h4:first-child,
.rich-text--smaller h5:first-child,
.rich-text--smaller h6:first-child,
.rich-text--smaller ul:first-child,
.rich-text--smaller ol:first-child,
.rich-text--smaller blockquote:first-child,
.rich-text--smaller p:first-child {
  margin-top: 0;
}
.rich-text h3 + p:not(:first-child), .rich-text h3 + ul:not(:first-child),
.rich-text h4 + p:not(:first-child),
.rich-text h4 + ul:not(:first-child),
.rich-text h5 + p:not(:first-child),
.rich-text h5 + ul:not(:first-child),
.rich-text h6 + p:not(:first-child),
.rich-text h6 + ul:not(:first-child),
.rich-text--smaller h3 + p:not(:first-child),
.rich-text--smaller h3 + ul:not(:first-child),
.rich-text--smaller h4 + p:not(:first-child),
.rich-text--smaller h4 + ul:not(:first-child),
.rich-text--smaller h5 + p:not(:first-child),
.rich-text--smaller h5 + ul:not(:first-child),
.rich-text--smaller h6 + p:not(:first-child),
.rich-text--smaller h6 + ul:not(:first-child) {
  margin-top: 0;
}
.rich-text ul:not(:first-child) ul, .rich-text ul:not(:first-child) ol,
.rich-text ol:not(:first-child) ul,
.rich-text ol:not(:first-child) ol,
.rich-text--smaller ul:not(:first-child) ul,
.rich-text--smaller ul:not(:first-child) ol,
.rich-text--smaller ol:not(:first-child) ul,
.rich-text--smaller ol:not(:first-child) ol {
  margin-top: 0;
}
.rich-text__abstract,
.rich-text--smaller__abstract {
  margin-bottom: 24px;
}
.rich-text img,
.rich-text iframe,
.rich-text--smaller img,
.rich-text--smaller iframe {
  max-width: 100%;
  height: auto;
}
.rich-text iframe,
.rich-text--smaller iframe {
  min-height: 200px;
}
.rich-text--hide-img img,
.rich-text--smaller--hide-img img {
  display: none;
}
.rich-text--hide-img img.force-show-img,
.rich-text--smaller--hide-img img.force-show-img {
  display: block;
}
.rich-text br,
.rich-text--smaller br {
  line-height: 10px;
}
.rich-text h3,
.rich-text--smaller h3 {
  font-size: 1.8rem;
  line-height: 2.6rem;
}
.rich-text h4,
.rich-text--smaller h4 {
  font-weight: 600;
}
.rich-text h5,
.rich-text--smaller h5 {
  font-weight: 600;
}
.rich-text h6,
.rich-text--smaller h6 {
  font-weight: 600;
}
.rich-text p,
.rich-text--smaller p {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
.rich-text a,
.rich-text--smaller a {
  position: relative;
  transition: color 150ms ease-in-out;
  border-bottom: solid 1px;
  padding-bottom: 1px;
  display: inline;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
.rich-text a:hover:not(.link--active), .rich-text a:focus:not(.link--active),
.rich-text--smaller a:hover:not(.link--active),
.rich-text--smaller a:focus:not(.link--active) {
  color: #c95555;
}
.rich-text a.remove-link-style,
.rich-text--smaller a.remove-link-style {
  color: inherit;
  border: none;
}
.rich-text a.remove-link-style:hover:not(.link--active), .rich-text a.remove-link-style:focus:not(.link--active),
.rich-text--smaller a.remove-link-style:hover:not(.link--active),
.rich-text--smaller a.remove-link-style:focus:not(.link--active) {
  color: inherit;
}
.rich-text a.button,
.rich-text a .button,
.rich-text--smaller a.button,
.rich-text--smaller a .button {
  cursor: pointer;
}
.rich-text .semi-bold,
.rich-text--smaller .semi-bold {
  font-weight: 400;
}
.rich-text .button, .rich-text a.button,
.rich-text a .button,
.rich-text--smaller .button,
.rich-text--smaller a.button,
.rich-text--smaller a .button {
  display: inline-block;
  margin-bottom: 0.5px;
}
.rich-text .button a,
.rich-text--smaller .button a {
  border: none;
}
.rich-text .button a:hover:not(.link--active), .rich-text .button a:focus:not(.link--active),
.rich-text--smaller .button a:hover:not(.link--active),
.rich-text--smaller .button a:focus:not(.link--active) {
  color: inherit;
}
.rich-text .button:hover,
.rich-text--smaller .button:hover {
  padding: 12px 24px;
  padding-bottom: 11px;
}
.rich-text ol,
.rich-text--smaller ol {
  list-style: decimal;
}
.rich-text ul,
.rich-text--smaller ul {
  list-style: disc;
}
.rich-text ol,
.rich-text ul,
.rich-text--smaller ol,
.rich-text--smaller ul {
  padding-left: 40px;
  margin-right: 20px;
}
.rich-text ol a,
.rich-text ul a,
.rich-text--smaller ol a,
.rich-text--smaller ul a {
  vertical-align: top;
}
.rich-text.rich-text__list-padding ul,
.rich-text--smaller.rich-text__list-padding ul {
  margin-top: 10px;
}
.rich-text strong,
.rich-text--smaller strong {
  font-weight: 600;
}
.rich-text__abstract + .rich-text,
.rich-text__abstract + .rich-text--smaller,
.rich-text--smaller__abstract + .rich-text,
.rich-text--smaller__abstract + .rich-text--smaller {
  margin-top: 24px;
}
.rich-text--blue, .rich-text .rich-text--blue,
.rich-text--smaller--blue,
.rich-text .rich-text--smaller--blue,
.rich-text--smaller .rich-text--blue,
.rich-text--smaller .rich-text--smaller--blue {
  color: #0c5273;
  font-size: 26px;
  font-weight: 200;
  line-height: 38px;
  margin-bottom: 24px;
}
.rich-text--blue p, .rich-text .rich-text--blue p,
.rich-text--smaller--blue p,
.rich-text .rich-text--smaller--blue p,
.rich-text--smaller .rich-text--blue p,
.rich-text--smaller .rich-text--smaller--blue p {
  font-size: 26px;
  font-weight: 200;
  line-height: 38px;
}
.rich-text--blue a, .rich-text .rich-text--blue a,
.rich-text--smaller--blue a,
.rich-text .rich-text--smaller--blue a,
.rich-text--smaller .rich-text--blue a,
.rich-text--smaller .rich-text--smaller--blue a {
  font-size: 26px;
}
.rich-text--blue strong, .rich-text .rich-text--blue strong,
.rich-text--smaller--blue strong,
.rich-text .rich-text--smaller--blue strong,
.rich-text--smaller .rich-text--blue strong,
.rich-text--smaller .rich-text--smaller--blue strong {
  font-weight: 400;
}
.rich-text__responsive-table,
.rich-text #table,
.rich-text--smaller__responsive-table,
.rich-text--smaller #table {
  margin: 2em 0;
  width: 94vw;
  overflow: auto;
}
.rich-text__responsive-table::-webkit-scrollbar,
.rich-text #table::-webkit-scrollbar,
.rich-text--smaller__responsive-table::-webkit-scrollbar,
.rich-text--smaller #table::-webkit-scrollbar {
  display: none;
}
.rich-text__responsive-table.responsive-table--small,
.rich-text #table.responsive-table--small,
.rich-text--smaller__responsive-table.responsive-table--small,
.rich-text--smaller #table.responsive-table--small {
  width: 94vw;
}
.rich-text__responsive-table.responsive-table--small table,
.rich-text #table.responsive-table--small table,
.rich-text--smaller__responsive-table.responsive-table--small table,
.rich-text--smaller #table.responsive-table--small table {
  width: 600px;
}
.rich-text table,
.rich-text--smaller table {
  width: 1280px;
  margin-top: 2em;
  overflow: auto;
  vertical-align: top;
  border-collapse: collapse;
}
.rich-text table a,
.rich-text--smaller table a {
  margin-right: 5%;
}
.rich-text table tr,
.rich-text--smaller table tr {
  border-top: 1px solid #b4b4b4;
  border-bottom: 1px solid #b4b4b4;
  margin-bottom: 20px;
}
.rich-text table th,
.rich-text table td,
.rich-text--smaller table th,
.rich-text--smaller table td {
  padding: 1rem 0.5rem 1rem 0;
}
.rich-text table th,
.rich-text--smaller table th {
  text-align: left;
}
.rich-text table td,
.rich-text--smaller table td {
  max-width: 130px;
  vertical-align: top;
  padding-bottom: 1rem;
}
.rich-text p.rich-text__component-border:not(:first-child),
.rich-text--smaller p.rich-text__component-border:not(:first-child) {
  border-top: solid 1px #b4b4b4;
  margin: 32px 0;
  height: 1px;
}
.rich-text__image-caption, .rich-text__video-caption,
.rich-text--smaller__image-caption,
.rich-text--smaller__video-caption {
  display: flex;
  vertical-align: top;
  flex-direction: column;
}
.rich-text__image-caption iframe,
.rich-text__image-caption img, .rich-text__video-caption iframe,
.rich-text__video-caption img,
.rich-text--smaller__image-caption iframe,
.rich-text--smaller__image-caption img,
.rich-text--smaller__video-caption iframe,
.rich-text--smaller__video-caption img {
  max-width: 100%;
}
.rich-text__image-caption span, .rich-text__video-caption span,
.rich-text--smaller__image-caption span,
.rich-text--smaller__video-caption span {
  margin-top: 15px;
  padding: 5px;
}
.rich-text__video-caption,
.rich-text--smaller__video-caption {
  overflow: hidden;
  position: relative;
}
.rich-text__video-caption iframe,
.rich-text--smaller__video-caption iframe {
  display: block;
  width: 560px;
  height: 315px;
}
.rich-text__video-caption span,
.rich-text--smaller__video-caption span {
  border: none;
}
@media (min-width: 480px) {
  .rich-text__responsive-table.responsive-table--small,
  .rich-text #table.responsive-table--small,
  .rich-text--smaller__responsive-table.responsive-table--small,
  .rich-text--smaller #table.responsive-table--small {
    width: 100%;
  }
  .rich-text__responsive-table.responsive-table--small table,
  .rich-text #table.responsive-table--small table,
  .rich-text--smaller__responsive-table.responsive-table--small table,
  .rich-text--smaller #table.responsive-table--small table {
    width: 100%;
  }
}
@media (min-width: 720px) {
  .rich-text iframe,
  .rich-text--smaller iframe {
    min-height: 250px;
  }
  .rich-text table th,
  .rich-text table td,
  .rich-text--smaller table th,
  .rich-text--smaller table td {
    padding: 2.25rem 1.25rem 2rem 0;
  }
  .rich-text table td,
  .rich-text--smaller table td {
    padding-bottom: 3rem;
  }
  .rich-text__video-caption, .rich-text__image-caption,
  .rich-text--smaller__video-caption,
  .rich-text--smaller__image-caption {
    flex-direction: row;
  }
  .rich-text__video-caption span, .rich-text__image-caption span,
  .rich-text--smaller__video-caption span,
  .rich-text--smaller__image-caption span {
    width: 27%;
    margin-left: 20px;
    margin-top: 0px;
    padding: 0px;
  }
  .rich-text p.rich-text__component-border:not(:first-child),
  .rich-text--smaller p.rich-text__component-border:not(:first-child) {
    margin: 72px 0;
  }
}
@media (min-width: 960px) {
  .rich-text iframe,
  .rich-text--smaller iframe {
    min-height: 360px;
  }
  .rich-text__abstract + .rich-text,
  .rich-text__abstract + .rich-text--smaller,
  .rich-text--smaller__abstract + .rich-text,
  .rich-text--smaller__abstract + .rich-text--smaller {
    margin-top: 46px;
  }
  .rich-text--blue, .rich-text .rich-text--blue,
  .rich-text--smaller--blue,
  .rich-text .rich-text--smaller--blue,
  .rich-text--smaller .rich-text--blue,
  .rich-text--smaller .rich-text--smaller--blue {
    color: #0c5273;
    margin-bottom: 46px;
  }
}

.rich-text h2.h2-style {
  font-size: 2.25rem;
  line-height: 2.9rem;
}
.rich-text--list-spacing a {
  color: #e06565;
}
.rich-text--list-spacing ul li {
  margin-top: 18px;
}
.rich-text--list-spacing ul li:first-child {
  margin-top: 0;
}

.rte-list {
  width: 100%;
}
.rte-list__title--border-top-half {
  padding-top: 48px;
  position: relative;
}
.rte-list__title--border-top-half:before {
  content: "";
  width: 100%;
  height: 1px;
  display: block;
  background: #b4b4b4;
  margin-bottom: 48px;
}
.rte-list__award__container {
  display: flex;
  flex-direction: column-reverse;
}
.rte-list__items {
  margin-top: 24px;
}
.rte-list__item:not(:first-child) {
  margin-top: 24px;
}
.rte-list__content ul li:not(:first-child) {
  margin-top: 24px;
}
.rte-list__content ul ul {
  list-style: disc;
  padding-left: 40px;
  margin-right: 20px;
}
.rte-list__content.rich-text--smaller ul li:not(:first-child) {
  margin-top: 10px;
}
.rte-list__more {
  margin-top: 24px;
}
@media (min-width: 720px) {
  .rte-list__award__container {
    flex-direction: row;
  }
  .rte-list__more {
    margin-top: 48px;
  }
}
@media (min-width: 960px) {
  .rte-list {
    display: flex;
    justify-content: space-between;
  }
  .rte-list--awards {
    justify-content: left;
  }
  .rte-list__award__container {
    width: 69%;
    justify-content: space-between;
  }
  .rte-list__title {
    width: 31%;
    margin-right: 20px;
  }
  .rte-list__title:before {
    content: none;
  }
  .rte-list__items {
    margin-top: 0;
    width: 69%;
    display: flex;
    flex-direction: column;
  }
  .rte-list__items--no-section-title {
    width: 100%;
  }
  .rte-list__items--border-top-half {
    position: relative;
  }
  .rte-list__items--border-top-half:before {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    background: #b4b4b4;
    margin-bottom: 48px;
  }
}

@media (min-width: 960px) {
  .rte-list-condensed:not(:last-child) {
    margin-bottom: 48px;
  }
}

.rte-list-alternate {
  width: 100%;
}
.rte-list-alternate__items {
  margin-top: 24px;
}
.rte-list-alternate__item {
  margin-top: 32px;
  padding-top: 32px;
}
.rte-list-alternate__item:not(:first-child) {
  border-top: solid 1px #b4b4b4;
}
.rte-list-alternate__item:last-child {
  margin-bottom: 32px;
  padding-bottom: 32px;
}
.rte-list-alternate__label {
  margin-bottom: 24px;
}
.rte-list-alternate__content__text {
  margin-bottom: 12px;
}
@media (min-width: 960px) {
  .rte-list-alternate__item {
    display: flex;
    justify-content: space-between;
  }
  .rte-list-alternate__label {
    width: 34%;
    padding-right: 10px;
  }
  .rte-list-alternate__content {
    width: 51%;
  }
}

.searchbar {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 14px;
  display: flex;
}
.searchbar--no-padding {
  padding: 0;
}
.searchbar__left {
  position: relative;
  flex: 1;
}
.searchbar__right {
  position: relative;
  margin-left: 1em;
}
.searchbar__input {
  box-sizing: border-box;
  width: 100%;
}
.searchbar__placeholder {
  position: absolute;
  left: 0;
  top: 50%;
  pointer-events: none;
  transform: translateY(-50%);
}
.searchbar__button {
  transition: opacity 0.3s ease-in-out;
}
.searchbar__button:hover, .searchbar__button:focus {
  opacity: 0.5;
}
.searchbar--dark {
  background-color: #0c5273;
}

.section {
  padding-top: 32px;
  padding-bottom: 32px;
}
.section--component > * {
  width: 100%;
}
.section__event-landing:first-child {
  padding-top: 32px;
}
.section--spacing-none {
  padding: 0;
  margin: 0;
}
.section--spacing-none:not(:first-child) {
  padding-top: 32px;
}
.section--component + .section--component:not(:first-child) {
  padding-top: 48px;
  padding-bottom: 48px;
  border-top: solid 1px #b4b4b4;
}
.section--component.section--force-border {
  padding-top: 48px;
  padding-bottom: 48px;
  border-top: solid 1px #b4b4b4;
  margin-top: -1px;
}
.section--border-top {
  border-top: solid 1px #b4b4b4;
}
.section--border-top--half:not(:first-child) {
  position: relative;
}
.section--border-top--half:not(:first-child):before {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  height: 1px;
  background: #b4b4b4;
  top: 0;
}
.section__aside {
  padding-bottom: 32px;
  border-bottom: solid 1px #b4b4b4;
  margin-bottom: 32px;
}
.section__aside .link {
  margin-bottom: 2px;
}
.section__single-column-inner {
  width: 100%;
}
@media (min-width: 720px) {
  .section {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 72px;
    padding-bottom: 72px;
    justify-content: space-between;
    width: 100%;
  }
  .section__event-landing:first-child {
    padding-top: 72px;
  }
  .section--spacing-none {
    padding: 0;
    margin: 0;
  }
  .section--spacing-none:not(:first-child) {
    padding-top: 72px;
  }
  .section--component + .section--component:not(:first-child) {
    padding-top: 86px;
    padding-bottom: 86px;
    border-top: solid 1px #b4b4b4;
  }
  .section--component.section--force-border {
    padding-top: 86px;
    padding-bottom: 86px;
  }
  .section--border-top--half:not(:first-child):before {
    width: 63%;
  }
  .section__single-section {
    flex-direction: row;
  }
  .section__main {
    width: 66%;
  }
  .section__main--full {
    width: 100%;
  }
  .section__aside {
    width: 25%;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
    flex-shrink: 1;
  }
}

.service-listing {
  color: #000000;
  margin-top: 1.7em;
}
.service-listing__name {
  color: #c95555;
  transition: color 0.35s ease-out;
}
.service-listing__name:hover {
  color: #000000;
}
.service-listing__sub-line {
  margin-bottom: 5px;
}
.style-guide__section {
  margin: 48px 0;
}
.style-guide__section-title {
  margin-bottom: 1rem;
  border-bottom: solid 1px #b4b4b4;
}
.style-guide__section-region {
  margin-top: 24px;
}

.tab-content {
  transition: opacity 0.35s ease-out;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition-delay: 0.35s;
  display: none;
}
.tab-content--active {
  display: block;
  opacity: 1;
  position: relative;
}

.tab-content > :last-child.cta-component-carousel,
.tab-content > :last-child.cta-component {
  margin-bottom: 72px;
}

.tabs__waypoint--sticky {
  padding-top: 66px;
}
.tabs__container {
  position: relative;
  transition: transform 0.5s;
  background: #ffffff;
}
.tabs__container__underline {
  height: 2px;
  margin: 0;
  background: #0c5273;
  border: none;
  transition: 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.tabs__container__menu {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tabs__container__menu:after, .tabs__container__menu:before {
  z-index: 2;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 2.5rem;
  pointer-events: none;
}
.tabs__container__menu:after {
  right: 0px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.001) 0%, rgb(255, 255, 255) 100%);
}
.tabs__container__menu:before {
  left: 0px;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0.001) 0%, rgb(255, 255, 255) 100%);
}
.tabs__container__menu__tab-list {
  display: inline-flex;
  flex-wrap: nowrap;
  position: relative;
  min-width: 100%;
}
.tabs__container__menu__tab-list:after {
  content: "";
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  height: 2px;
  background-color: #e6e7e8;
  z-index: 1;
  display: block;
  margin-top: -2px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.tabs__container__menu__tab {
  white-space: nowrap;
  cursor: pointer;
  padding: 24px 4px;
  padding-bottom: 16px;
  position: relative;
  border-bottom: solid 2px rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
  z-index: 1;
}
.tabs__container__menu__tab:focus {
  outline: 0;
}
.tabs__container__menu__tab:hover {
  color: #0c5273;
}
.tabs__container__menu__tab--active {
  color: #0c5273;
}
.tabs__container__menu__tab:not(:first-child) {
  margin-left: 44px;
}
.tabs__container--sticky {
  position: fixed;
  top: 66px;
  z-index: 9;
  display: block;
  background: white;
  width: 100%;
  border-top: 2px solid #e6e7e8;
}
.tabs--sticky {
  position: fixed;
  top: 66px;
  z-index: 9;
  display: block;
  background: white;
  width: 100%;
}
.tabs--hide {
  transform: translateY(-66px);
  transition: transform 0.5s;
  border-top: none;
}
.tabs--with-gradient {
  margin-left: -40px;
  padding-left: 40px;
}
.tabs__filler {
  padding-right: 100px;
}
.tabs__bar {
  height: 2px;
  background-color: #b4b4b4;
}
.tabs ::-webkit-scrollbar {
  display: none;
}
@media (max-width: 480px) {
  .tabs__container--hide {
    display: none;
  }
}
@media (min-width: 480px) {
  .tabs__menu {
    display: inline-flex;
  }
}
@media (min-width: 720px) {
  .tabs__container__menu {
    overflow: hidden;
  }
  .tabs__menu:after {
    content: none;
  }
}

.text-carousel__dot {
  opacity: 0.5;
  font-size: 0.75em;
  margin-right: 0.5em;
}
.text-carousel__dot--active {
  opacity: 1;
}
.text-carousel__dot:focus {
  outline: none;
}
.text-carousel__container {
  flex-direction: column;
  overflow: hidden;
  display: flex;
}
.text-carousel__left {
  flex: 1;
  display: inline-block;
}
@media (min-width: 480px) {
  .text-carousel__left {
    flex: none;
    padding-right: 25px;
  }
}
@media (min-width: 720px) {
  .text-carousel__left {
    flex: none;
    padding-right: 75px;
  }
}
.text-carousel__left__header {
  margin: 1em 0;
}
.theme-coral .text-carousel__left__menu {
  color: #e06565;
}
.theme-cyan .text-carousel__left__menu {
  color: #039ecc;
}
.theme-teal .text-carousel__left__menu {
  color: #3da3a3;
}
.theme-medium-blue .text-carousel__left__menu {
  color: #156289;
}
.theme-yellow .text-carousel__left__menu {
  color: #f9b36a;
}
.theme-gray .text-carousel__left__menu {
  color: #7D7E81;
}
.theme-navy .text-carousel__left__menu {
  color: #1e283c;
}
.text-carousel__left__menu__item {
  display: none;
  cursor: pointer;
  opacity: 0.25;
}
.text-carousel__left__menu__item--active {
  opacity: 1;
}
.text-carousel__left__menu__header {
  margin-bottom: 0.5em;
}
.theme-coral .text-carousel__left__menu__header {
  color: #e06565;
}
.theme-cyan .text-carousel__left__menu__header {
  color: #039ecc;
}
.theme-teal .text-carousel__left__menu__header {
  color: #3da3a3;
}
.theme-medium-blue .text-carousel__left__menu__header {
  color: #156289;
}
.theme-yellow .text-carousel__left__menu__header {
  color: #f9b36a;
}
.theme-gray .text-carousel__left__menu__header {
  color: #7D7E81;
}
.theme-navy .text-carousel__left__menu__header {
  color: #1e283c;
}
.text-carousel__right {
  display: inline-flex;
  flex: none;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: transform 0.35s ease-out;
}
.text-carousel__right__container {
  position: relative;
  flex: 1 0 100%;
}
.text-carousel__right__image-container {
  display: flex;
  flex-direction: column;
}
.text-carousel__right__image-container__image {
  width: 100%;
  margin-bottom: 1em;
}
.text-carousel__right__quote {
  position: relative;
}
.text-carousel__right__quote:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
  font-size: 30px;
  display: block;
  margin-bottom: 25px;
}
.theme-coral .text-carousel__right__quote:before {
  color: #e06565;
}
.theme-cyan .text-carousel__right__quote:before {
  color: #039ecc;
}
.theme-teal .text-carousel__right__quote:before {
  color: #3da3a3;
}
.theme-medium-blue .text-carousel__right__quote:before {
  color: #156289;
}
.theme-yellow .text-carousel__right__quote:before {
  color: #f9b36a;
}
.theme-gray .text-carousel__right__quote:before {
  color: #7D7E81;
}
.theme-navy .text-carousel__right__quote:before {
  color: #1e283c;
}
@media (min-width: 480px) {
  .text-carousel {
    flex-direction: row;
    min-height: 300px;
  }
  .text-carousel__container {
    flex-direction: row;
    min-height: 330px;
  }
  .text-carousel__left {
    width: 50%;
  }
  .text-carousel__left__header {
    margin: 0;
  }
  .text-carousel__left__menu__item {
    display: block;
  }
  .text-carousel__right {
    width: 50%;
    flex: 1;
    display: inline-block;
  }
  .text-carousel__right__container {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
  .text-carousel__right__container--active {
    height: auto;
    visibility: visible;
    opacity: 1;
    transition: opacity 1s ease;
  }
  .text-carousel__dots {
    display: none;
  }
}
@media (min-width: 960px) {
  .text-carousel__container {
    min-height: 400px;
  }
}

.video {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.video--one .video__container, .video--two .video__container, .video--three .video__container {
  width: 100%;
}
.video__container {
  position: relative;
  margin-bottom: 24px;
}
.video__container--pull {
  display: flex;
  flex-direction: column;
}
.video__container__preview {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  padding: 20px;
  background-size: cover;
  background-position: center;
}
.video__container__preview--hidden {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.video__container__preview__play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 50%;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 14px 10px 14px 14px;
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.35s ease-out;
  display: flex;
}
.video__container__preview__play:hover {
  transform: translateX(-50%) translateY(-50%) scale(1.25);
}
.video__container__frame {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 1em;
}
.video__container__frame--pull {
  padding-top: 38.25%;
}
.video__container__frame iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 960px) {
  .video {
    flex-direction: row;
  }
  .video--two .video__container {
    width: 50%;
  }
  .video--three .video__container {
    width: 33.33%;
  }
  .video--two, .video--three {
    margin-left: -20px;
    margin-right: -20px;
  }
  .video__container {
    padding: 20px;
  }
  .video__container__caption--pull {
    flex-basis: 33%;
  }
  .video__container__frame--pull {
    width: 100%;
    margin-right: 40px;
  }
  .video__container--pull {
    flex-direction: row;
  }
}

.cta-component {
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  width: 100vw;
}
.cta-component__gradient {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(270deg, rgba(30, 40, 60, 0) 0%, #000000 100%);
}
.cta-component__gradient--content {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
.cta-component__text {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  padding-left: 60px;
  color: #ffffff;
}
.cta-component__text__header {
  margin-bottom: 32px;
}
.cta-component__text__body {
  margin-bottom: 32px;
}
.cta-component__text__body blockquote {
  margin-left: 0;
}
@media (min-width: 960px) {
  .cta-component__text--left {
    padding-right: 40%;
  }
  .cta-component__text--right {
    padding-left: 40%;
  }
  .cta-component__text {
    margin-top: 18px;
  }
  .cta-component__cta {
    margin-top: 48px;
  }
}

.cta-component-image {
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 1440px;
}
.cta-component-image--full {
  justify-content: center;
}
.cta-component-image--full .cta-component-image__image {
  display: none;
}
.cta-component-image--full .cta-component-image__text {
  justify-content: center;
}
.cta-component-image--full .cta-component-image__gradient,
.cta-component-image--full .cta-component-image__text:not(.carousel__controls),
.cta-component-image--full .cta-component-image__image {
  min-height: 500px;
}
.cta-component-image--offset {
  background-position: 0px calc(-80px * 2);
  background-repeat: no-repeat;
  justify-content: flex-end;
}
.cta-component-image--offset .cta-component-image__text {
  margin-top: 80px;
  margin-right: 80px;
}
.cta-component-image--half .cta-component-image__text, .cta-component-image--two-thirds .cta-component-image__text {
  justify-content: start;
}
.cta-component-image--half .cta-component-image__text:not(.carousel__controls), .cta-component-image--two-thirds .cta-component-image__text:not(.carousel__controls) {
  min-height: 500px;
}
.cta-component-image--half:not(.cta-component-image--offset) .cta-component-image__text.cta-component-image__text {
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
.cta-component-image__gradient {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(270deg, rgba(30, 40, 60, 0) 0%, #000000 100%);
}
.cta-component-image__gradient--content {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
.cta-component-image__gradient--left {
  background-image: linear-gradient(90deg, rgba(30, 40, 60, 0) 0%, #000000 100%);
}
.cta-component-image__gradient--right {
  background-image: linear-gradient(270deg, rgba(30, 40, 60, 0) 0%, #000000 100%);
}
.cta-component-image__image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 50%;
  width: 100%;
}
.cta-component-image__image__image {
  padding: 20px;
}
.cta-component-image__image__image img {
  width: 100%;
}
.cta-component-image__text {
  z-index: 2;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  padding: 40px 50px;
}
.theme-coral .cta-component-image__text {
  background: #e06565;
}
.theme-cyan .cta-component-image__text {
  background: #039ecc;
}
.theme-teal .cta-component-image__text {
  background: #3da3a3;
}
.theme-medium-blue .cta-component-image__text {
  background: #156289;
}
.theme-yellow .cta-component-image__text {
  background: #f9b36a;
}
.theme-gray .cta-component-image__text {
  background: #7D7E81;
}
.theme-navy .cta-component-image__text {
  background: #1e283c;
}
.cta-component-image__text__read {
  align-self: flex-start;
}
.cta-component-image__text__date {
  margin-bottom: 12px;
}
.cta-component-image__text__eyebrow {
  margin-bottom: 12px;
}
.cta-component-image__text--half, .cta-component-image__text--two-thirds {
  padding-bottom: 100px;
}
.cta-component-image__text__header {
  margin-bottom: 32px;
}
.cta-component-image__text__body {
  margin-bottom: 32px;
}
@media (min-width: 960px) {
  .cta-component-image {
    flex-direction: row;
  }
  .cta-component-image--right {
    flex-direction: row-reverse;
  }
  .cta-component-image--full .cta-component-image__image {
    display: block;
  }
  .cta-component-image--half .cta-component-image__text, .cta-component-image--two-thirds .cta-component-image__text {
    justify-content: start;
  }
  .cta-component-image--offset {
    background-position: 0px calc(-80px);
  }
  .cta-component-image--offset .cta-component-image__text {
    margin-right: 0px;
  }
  .cta-component-image__image {
    padding-top: 66%;
    padding-top: 0;
  }
  .cta-component-image__image--half {
    width: 50%;
  }
  .cta-component-image__image--two-thirds {
    width: 66.66%;
  }
  .cta-component-image__text {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 80px;
  }
  .cta-component-image__text__dots {
    padding-left: 32px;
    padding-right: 32px;
  }
  .cta-component-image__text--half, .cta-component-image__text--two-thirds {
    padding-bottom: 40px;
  }
  .cta-component-image__text--two-thirds {
    width: 33.33%;
  }
  .cta-component-image__text--half {
    width: 50%;
  }
}
@media (min-width: 960px) {
  .cta-component-image__cta {
    margin-top: 48px;
  }
}

.cta-component-carousel {
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
}
.cta-component-carousel__left {
  padding-left: 60px;
}
.cta-component-carousel__right-arrow {
  right: 15px;
}
.cta-component-carousel__left-arrow {
  left: 15px;
}
.cta-component-carousel .carousel__dot {
  color: white;
  opacity: 0.5;
}
.cta-component-carousel .carousel__dot--active {
  opacity: 1;
}
.cta-component-carousel .cta-component {
  margin-left: 0;
  margin-right: 0;
}
.cta-component-carousel .cta-component-carousel__controls {
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}
.cta-component-carousel .cta-component-carousel__controls .cta-component__left {
  padding-top: 0;
  padding-bottom: 0;
}
.cta-component-carousel.carousel__slides-wrapper {
  width: auto;
  position: relative;
}
.cta-component-carousel__controls {
  padding-left: 16px;
}
@media (min-width: 960px) {
  .cta-component-carousel .cta-component {
    margin-left: -50vw;
    margin-right: -50vw;
  }
  .cta-component-carousel__left-arrow {
    left: 20px;
  }
  .cta-component-carousel__left-arrow, .cta-component-carousel__right-arrow {
    font-size: 30px;
  }
  .cta-component-carousel.carousel__slides-wrapper {
    width: calc(100vw - 8px);
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}

.cta-custom-component {
  margin: 50px 0;
  padding: 100px 0;
  text-align: center;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.theme-coral .cta-custom-component {
  background: #e06565;
}
.theme-cyan .cta-custom-component {
  background: #039ecc;
}
.theme-teal .cta-custom-component {
  background: #3da3a3;
}
.theme-medium-blue .cta-custom-component {
  background: #156289;
}
.theme-yellow .cta-custom-component {
  background: #f9b36a;
}
.theme-gray .cta-custom-component {
  background: #7D7E81;
}
.theme-navy .cta-custom-component {
  background: #1e283c;
}
.cta-custom-component__text {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
}
.theme-coral .cta-custom-component__text {
  color: #000000;
}
.theme-cyan .cta-custom-component__text {
  color: #000000;
}
.theme-teal .cta-custom-component__text {
  color: #000000;
}
.theme-medium-blue .cta-custom-component__text {
  color: #000000;
}
.theme-yellow .cta-custom-component__text {
  color: #000000;
}
.theme-gray .cta-custom-component__text {
  color: #000000;
}
.theme-navy .cta-custom-component__text {
  color: #ffffff;
}
.cta-custom-component__text--left {
  text-align: left;
}
.cta-custom-component__text--center {
  text-align: center;
}
.cta-custom-component__text--right {
  text-align: right;
}
.cta-custom-component__text__header {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 32px;
}
.cta-custom-component__text__body {
  font-size: 1.2rem;
  margin-bottom: 32px;
}
.cta-custom-component__text__cta-button {
  padding: 10px 35px;
  font-size: 16px;
  letter-spacing: 0.5px;
}
.theme-coral .cta-custom-component__text__cta-button {
  color: #ffffff;
  background-color: #1e283c;
  border: none;
}
.theme-cyan .cta-custom-component__text__cta-button {
  color: #ffffff;
  background-color: #1e283c;
  border: none;
}
.theme-teal .cta-custom-component__text__cta-button {
  color: #ffffff;
  background-color: #1e283c;
  border: none;
}
.theme-medium-blue .cta-custom-component__text__cta-button {
  color: #ffffff;
  background-color: #1e283c;
  border: none;
}
.theme-yellow .cta-custom-component__text__cta-button {
  color: #ffffff;
  background-color: #1e283c;
  border: none;
}
.theme-gray .cta-custom-component__text__cta-button {
  color: #ffffff;
  background-color: #1e283c;
  border: none;
}
.theme-navy .cta-custom-component__text__cta-button {
  color: #ffffff;
}
.cta-custom-component__text__cta-button:hover {
  padding: 14px 48px;
}

.map {
  background: #e06565;
}
.map path {
  fill: #e06565;
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1;
  transition: 0.5s all linear;
}
.map path:hover {
  fill: #e06565;
  transition: 0s all linear;
  cursor: pointer;
}

.twitter__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  flex-grow: 1;
}
.twitter__container__tweet {
  width: 100%;
  position: relative;
  padding: 20px;
  flex-shrink: 1;
  margin-bottom: 20px;
}
@media (min-width: 720px) {
  .twitter__container {
    flex-direction: row;
  }
  .twitter__container__tweet {
    width: 50%;
  }
}
@media (min-width: 960px) {
  .twitter__container__tweet {
    width: 33.33%;
  }
}

.Tweet-header {
  display: none;
}

.events__container {
  margin-bottom: 40px;
}
.events__header {
  margin-bottom: 30px;
}
.events__description {
  margin-bottom: 20px;
}
.events__event {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  padding: 30px 0;
}
.events__event:not(:first-child) {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: solid 1px #b4b4b4;
}
.section--component .events__event:last-child {
  padding-bottom: 0;
}
.events__event__details {
  margin-bottom: 24px;
}
.events__event__details--single-event {
  width: 100%;
}
.events__event__details__location {
  font-size: 18px;
}
.events__event__hosted {
  color: #0c5273;
}
.events__event__description {
  margin-bottom: 30px;
}
.events__event__header {
  margin-bottom: 30px;
}
@media (min-width: 720px) {
  .events__event {
    flex-direction: row-reverse;
  }
  .events__event__description {
    flex-grow: 1;
    margin: 0px 11%;
  }
  .events__event__details {
    width: 165px;
    flex-shrink: 0;
    overflow-wrap: break-word;
    margin-bottom: 0;
  }
  .events__event__details--single-event {
    width: 100%;
    margin-bottom: 24px;
  }
  .events__event__header {
    width: 33%;
  }
  .events .events__event {
    padding-top: 48px;
    padding-bottom: 40px;
  }
}
.events-landing__rich-text {
  margin-bottom: 0px;
}

.related-practices {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.related-practices__left {
  padding-right: 40px;
}
.related-practices__left__header {
  margin-bottom: 1em;
}
.related-practices__left__header.component-container__header {
  margin-bottom: 1em;
}
.related-practices__right-image-wrapper {
  display: none;
  text-align: center;
  vertical-align: top;
}
.related-practices__accordion-wrapper {
  display: flex;
  flex-direction: column;
}
.related-practices__right__practice {
  position: relative;
}
.related-practices__right__practice--active .related-practices__right__practice__header:after {
  content: "\e906";
}
.related-practices__right__practice__header {
  cursor: pointer;
  position: relative;
}
.related-practices__right__practice__header:after {
  pointer-events: none;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90a";
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.related-practices__right__practice__body {
  margin: 1em 0;
  visibility: hidden;
}
.related-practices__right__practice__body__text {
  margin-bottom: 1em;
}
@media (min-width: 480px) {
  .related-practices {
    flex-direction: row;
  }
  .related-practices__right-image-wrapper {
    display: block;
  }
  .related-practices__right, .related-practices__left {
    flex: 1;
  }
}
@media (min-width: 720px) {
  .related-practices__right__practice__header:after {
    font-size: 18px;
  }
}
@media (min-width: 960px) {
  .related-practices__right__practice {
    transition: margin 0.5s linear;
  }
  .related-practices__right__practice--active {
    margin-bottom: 24px;
  }
  .related-practices__right__practice--active:last-child {
    margin-bottom: 0;
  }
}

.contact {
  display: flex;
  flex-direction: column;
}
.contact h3:not(:first-child),
.contact h4:not(:first-child),
.contact h5:not(:first-child),
.contact h6:not(:first-child),
.contact p:not(:first-child) {
  margin-top: 1rem;
}
.contact__right, .contact__left {
  flex: 1;
}
.contact__left__header {
  margin-top: -4px;
  margin-bottom: 1em;
}
.contact__right__text {
  margin-bottom: 2em;
}
@media (min-width: 480px) {
  .contact {
    flex-direction: row;
  }
}

.contacts {
  width: 100%;
}
.contacts__label {
  margin-bottom: 24px;
}
.contacts__abstract {
  margin-bottom: 24px;
}
.contacts__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 720px) {
  .contacts__label {
    margin-bottom: 48px;
  }
  .contacts__abstract {
    margin-bottom: 48px;
  }
  .contacts__container {
    margin: 0 -20px;
    flex-direction: row;
  }
  .contacts__container--two .contact-card {
    width: 50%;
  }
  .contacts__container--two .contact-card__image img {
    max-height: 303px;
  }
  .contacts__container--three .contact-card {
    width: 33.33%;
  }
}
.contacts__background-style .contact-card__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.contacts__background-style .contact-card__image--hidden {
  visibility: hidden;
}

.contact-card {
  width: 100%;
  margin-bottom: 48px;
}
.contact-card__image {
  width: 100%;
}
.contact-card__image--bg {
  padding-bottom: 56%;
  background-position: 91% 5%;
  background-size: 215%;
  background-repeat: no-repeat;
}
.contact-card__image img {
  width: 100%;
  max-height: 229px;
}
.contact-card__name {
  margin-top: 24px;
}
.contact-card__title {
  text-transform: uppercase;
}
.contact-card__subtitle {
  color: #7D7E81;
}
.contact-card__top-content {
  margin-bottom: 15px;
}
.contact-card__city a + .contact-card__city a:before {
  content: ", ";
  font-weight: 100;
}
.contact-card__email {
  margin-bottom: 24px;
}
.contact-card__cta {
  text-transform: uppercase;
}
@media (min-width: 720px) {
  .contact-card {
    padding: 20px;
  }
}

.contact-card__city__link + .contact-card__city__link:before {
  content: ", ";
  font-weight: 100;
}

.contacts__featured .contact-card__city__link + .contact-card__city__link:before {
  content: ", ";
  font-weight: 100;
  margin-left: -0.25rem;
}

.team-landing__header__label {
  flex-grow: 1;
  margin-bottom: 24px;
}
.team-landing__header__abstract {
  margin-bottom: 24px;
}
.team-landing__header__filters {
  display: flex;
  flex-direction: column;
}
.team-landing__landing {
  flex-wrap: wrap;
}
@media (min-width: 720px) {
  .team-landing__header {
    flex-direction: row;
  }
  .team-landing__header__filters {
    flex-direction: row;
  }
  .team-landing__header__abstract {
    margin-bottom: 48px;
  }
  .team-landing__landing {
    flex-wrap: wrap;
  }
  .team-landing__landing .contact-card {
    width: 33.33%;
  }
}

.leadership .contact-card__cta {
  display: none;
}

.carousel {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  color: #1e283c;
  flex: 1 1 0px;
}
@media (min-width: 960px) {
  .carousel .cta-component-image {
    height: 30rem;
  }
}
@media (max-width: 960px) {
  .carousel .cta-component-image .cta-component-image__text:not(.carousel__controls) {
    min-height: auto;
  }
}
.carousel::after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.carousel__arrows {
  position: absolute;
  left: 50%;
  top: 0px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  padding: 5px;
  transform: translateX(-50%);
  pointer-events: none;
}
.carousel__arrow {
  width: 35px;
  height: 35px;
  border: 1px solid #7D7E81;
  color: #7D7E81;
  background-color: #FFF;
  font-size: 14px;
  border-radius: 50%;
  transition: all 0.3s ease-out;
  text-align: center;
  cursor: pointer;
  opacity: 0.9;
  pointer-events: all;
}
.carousel__arrow:focus {
  outline: 0;
}
.carousel__left {
  padding-left: 60px;
}
.carousel__right-arrow {
  z-index: 5;
}
.carousel__right-arrow--grey {
  color: black;
}
.carousel__left-arrow {
  z-index: 5;
}
.carousel__left-arrow--grey {
  color: black;
}
.carousel__slides-wrapper {
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.carousel__slides {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  flex: 1 0 auto;
  transition: transform 300ms ease-in-out;
}
.carousel__slide {
  min-width: 100%;
  justify-content: center;
  display: flex;
}
@media (min-width: 960px) {
  .carousel__slide {
    height: 500px;
  }
}
.carousel__controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #1e283c;
  position: absolute;
  z-index: 2;
  margin: 0 auto;
  max-width: 90rem;
  bottom: 0px;
  left: 50%;
  color: white;
  transform: translateX(-50%);
  pointer-events: none;
}
.carousel__controls--image-left {
  flex-direction: row-reverse;
}
.carousel__left {
  transform: rotate(180deg);
}
.carousel__dots {
  display: flex;
}
.carousel__dots--half {
  margin-left: auto;
}
.carousel__dots--dark .carousel__dot {
  color: black;
  opacity: 0.5;
}
.carousel__dots--dark .carousel__dot--active {
  opacity: 1;
}
.carousel__dot {
  color: #ffffff;
  font-size: 12px;
  opacity: 0.7;
  pointer-events: all;
}
.carousel__dot:focus {
  outline: none;
}
.carousel__dot:not(:first-child) {
  margin-left: 0.5rem;
}
.carousel__dot--active {
  opacity: 1;
}
@media (min-width: 720px) {
  .carousel__dots {
    margin-left: 0px;
  }
  .carousel__arrows {
    padding: 15px;
  }
  .carousel__arrow--light {
    color: #ffffff;
  }
  .carousel__arrow:hover {
    color: #ffffff;
    background-color: #7D7E81;
  }
}

body {
  overflow-x: hidden;
}

.tombstone-container {
  width: 100%;
}
.tombstone-container__header {
  margin-bottom: 24px;
}
.tombstone-container__subheader {
  margin-bottom: 24px;
}
@media (min-width: 720px) {
  .tombstone-container__header {
    margin-bottom: 48px;
  }
  .tombstone-container__subheader {
    margin-bottom: 48px;
  }
}

.tombstone-grid-container {
  margin-bottom: 24px;
}
.tombstone-grid-container__grid {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tombstone-item {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
}
.tombstone-item:not(:first-child) {
  border-top: solid 1px #b4b4b4;
}
.section--component .tombstone-item:last-child {
  padding-bottom: 0;
}
.tombstone-item__logo {
  display: flex;
  margin-bottom: 24px;
  align-self: flex-start;
}
.tombstone-item__logo__image {
  max-width: 100%;
}
@media (min-width: 480px) {
  .tombstone-item__logo__image {
    max-width: 215px;
    margin-right: 10px;
  }
}
.tombstone-item__logo__image img {
  width: 100%;
}
.tombstone-item__text {
  margin: auto 0;
  width: 100%;
}
.tombstone-item__text__header {
  margin-bottom: 10px;
}
.tombstone-item__link {
  margin-top: 10px;
}
@media (min-width: 720px) {
  .tombstone-item {
    flex-direction: row;
  }
  .tombstone-item__logo {
    flex-grow: 1;
    flex-basis: 50%;
    justify-content: center;
    margin-bottom: 0px;
  }
  .tombstone-item__text {
    flex-grow: 1;
  }
}

.tombstone-grid-item {
  padding: 20px;
  margin-bottom: 24px;
}
.tombstone-grid-item__logo {
  background-color: rgba(230, 231, 232, 0.3);
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  padding: 48px 0;
  height: 250px;
  width: 100%;
}
.tombstone-grid-item__logo__image {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tombstone-grid-item__logo__image img {
  width: 100%;
  height: 100%;
  max-height: 153px;
  max-width: 300px;
}
.tombstone-grid-item__link {
  margin-top: 1.25rem;
}
.tombstone-grid-item__text__body {
  padding: 0.3em 0;
}
.tombstone-grid-item__text__body a {
  margin-top: 24px;
  position: relative;
  overflow: hidden;
}
.tombstone-grid-item__text__body a:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
}
.tombstone-grid-item__text__body a:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 20px;
  background: black;
  height: 1px;
  width: 100%;
}
@media (min-width: 720px) {
  .tombstone-grid-item {
    width: 50%;
  }
}
@media (min-width: 960px) {
  .tombstone-grid-item {
    width: 33%;
  }
}

.component-card {
  margin-bottom: 48px;
}
.component-card__bar {
  height: 4px;
  width: 100%;
  background: #7D7E81;
  margin-bottom: 20px;
}
.theme-coral .component-card__bar {
  background: #e06565;
}
.theme-cyan .component-card__bar {
  background: #039ecc;
}
.theme-teal .component-card__bar {
  background: #3da3a3;
}
.theme-medium-blue .component-card__bar {
  background: #156289;
}
.theme-yellow .component-card__bar {
  background: #f9b36a;
}
.theme-gray .component-card__bar {
  background: #7D7E81;
}
.theme-navy .component-card__bar {
  background: #1e283c;
}
.component-card__icon {
  height: 0;
  width: 100%;
  padding-top: 67%;
  background-size: 300px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}
.component-card__icon--cover {
  background-size: cover;
}
.theme-coral .component-card__icon {
  background-color: #e06565;
}
.theme-cyan .component-card__icon {
  background-color: #039ecc;
}
.theme-teal .component-card__icon {
  background-color: #3da3a3;
}
.theme-medium-blue .component-card__icon {
  background-color: #156289;
}
.theme-yellow .component-card__icon {
  background-color: #f9b36a;
}
.theme-gray .component-card__icon {
  background-color: #7D7E81;
}
.theme-navy .component-card__icon {
  background-color: #1e283c;
}
.component-card__image {
  height: 0;
  width: 100%;
  padding-top: 67%;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}
.component-card__eyebrow {
  text-transform: none;
}
.component-card__type {
  margin-top: 2px;
  text-transform: uppercase;
}
.component-card__type--title-case {
  text-transform: none;
}
.theme-coral .component-card__type {
  color: #e06565;
}
.theme-cyan .component-card__type {
  color: #039ecc;
}
.theme-teal .component-card__type {
  color: #3da3a3;
}
.theme-medium-blue .component-card__type {
  color: #156289;
}
.theme-yellow .component-card__type {
  color: #f9b36a;
}
.theme-gray .component-card__type {
  color: #7D7E81;
}
.theme-navy .component-card__type {
  color: #1e283c;
}
.component-card__type + .component-card__category {
  margin-bottom: 0px;
}
.component-card__title {
  padding: 0.5em 0;
  transition: transform 0.35s ease-out;
  display: block;
}
.component-card__more {
  margin-top: 20px;
}
@media (min-width: 720px) {
  .component-card {
    padding: 20px;
  }
}

@media (min-width: 720px) {
  .component-card-container {
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .component-card-container--two .component-card, .component-card-container--full .component-card {
    width: 50%;
  }
  .component-card-container--three .component-card {
    width: 33%;
  }
}
@media (min-width: 960px) {
  .component-card-container--two .component-card {
    width: 50%;
  }
  .component-card-container--three .component-card, .component-card-container--full .component-card {
    width: 33%;
  }
}

.component-container__header {
  margin-bottom: 24px;
}
.component-container__subheader-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.component-container__subheader {
  margin-bottom: 24px;
  width: 100%;
}
.component-container__subheader--with-nav {
  width: 75%;
}
.component-container__body {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.component-container__body__left__text {
  margin-bottom: 24px;
}
@media (min-width: 480px) {
  .component-container__body {
    flex-direction: row;
  }
  .component-container__body__left {
    flex-basis: 50%;
  }
  .component-container__body__right {
    flex-basis: 50%;
  }
}
@media (min-width: 720px) {
  .component-container__subheader-container {
    flex-direction: row;
  }
}
@media (min-width: 960px) {
  .component__header {
    margin-bottom: 48px;
  }
  .component__subheader {
    max-width: 700px;
    margin-bottom: 48px;
  }
}

.initiative-container {
  width: 100%;
}
.initiative-container__grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 480px) {
  .initiative-container__grid {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.initiative {
  padding: 10px;
  width: 100%;
}
.initiative__image {
  background: rgba(230, 231, 232, 0.3);
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  padding-top: 75%;
  position: relative;
}
.initiative__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 100%;
  transition: transform 0.35s ease-out, filter 0.35s ease-out;
}
@media (min-width: 480px) {
  .initiative {
    width: 50%;
  }
}
@media (min-width: 720px) {
  .initiative {
    width: 33%;
  }
}
@media (min-width: 960px) {
  .initiative {
    width: 25%;
  }
  .initiative__image img {
    filter: grayscale(100%);
  }
  .initiative__image img:hover {
    filter: grayscale(0);
    transform: translate(-50%, -50%) scale(1.15);
    transition: transform 0.35s ease-out, filter 0.35s ease-out;
  }
}

.break {
  flex-basis: 100%;
  width: 0px;
  height: 0px;
  overflow: hidden;
  display: none;
}
@media (min-width: 720px) {
  .break {
    display: block;
  }
}

.theme-coral .award-full-logo {
  background: #e06565;
}
.theme-cyan .award-full-logo {
  background: #039ecc;
}
.theme-teal .award-full-logo {
  background: #3da3a3;
}
.theme-medium-blue .award-full-logo {
  background: #156289;
}
.theme-yellow .award-full-logo {
  background: #f9b36a;
}
.theme-gray .award-full-logo {
  background: #7D7E81;
}
.theme-navy .award-full-logo {
  background: #1e283c;
}

.award__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 260px 135px;
}
.award__badge--full-bg {
  background-size: cover;
}
@media (min-width: 720px) {
  .award__badge {
    background-size: 420px 270px;
  }
  .award__badge--full-bg {
    background-size: cover;
  }
  .award__badge img {
    max-width: 420px;
    max-height: 270px;
  }
}

.award-recognition {
  width: 100px;
  height: auto;
  margin-bottom: 24px;
  margin-right: 12px;
}
.award-recognition img {
  width: 100%;
}

.award-recognition-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 25px 60px 0 30px;
  min-width: 100px;
}
@media (min-width: 720px) {
  .award-recognition-container {
    flex-direction: column;
  }
}
.office-card {
  width: 100%;
  margin-bottom: 48px;
}
.office-card__image {
  width: 100%;
}
.office-card__image img {
  width: 100%;
}
.office-card__title {
  margin: 24px 0;
}
@media (min-width: 480px) {
  .office-card {
    max-width: 33.33%;
    padding: 25px 10px;
  }
}

.office-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 480px) {
  .office-card-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.office-contact {
  margin-bottom: 24px;
  line-height: 1.4;
}
.office-contact__address {
  max-width: 260px;
  line-height: 1.4;
  display: flex;
}
.office-contact__address__icon {
  margin-right: 10px;
}
.office-contact__address__icon:before {
  margin: -3px;
}
.office-contact__address__address {
  position: relative;
  top: -4px;
}
.office-contact__telephone {
  display: flex;
  line-height: 1.4;
}
.office-contact__telephone__label {
  margin-right: 10px;
}
.office-contact__fax {
  display: flex;
}
.office-contact__fax__label {
  margin-right: 10px;
}

.link-list-component {
  width: 100%;
}
.link-list-component__left {
  padding-right: 25px;
}
@media (min-width: 720px) {
  .link-list-component__left--extra-spacing {
    padding-right: 75px;
  }
}
.link-list-component__list__link {
  margin-bottom: 8px;
  transition: color 0.35s ease-out;
}
.link-list-component__list__link a:hover {
  transition: color 0.35s ease-out;
  color: #e06565;
}

.diversity__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.diversity__subheader {
  width: 75%;
}
@media (min-width: 720px) {
  .diversity__container {
    flex-direction: row;
  }
}

.blog-about {
  width: 100%;
}
.blog-slide {
  background-size: cover;
  background-position: center;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blog-slide__left {
  position: relative;
  padding-top: 72px;
  padding-bottom: 72px;
  padding-left: 60px;
  color: #ffffff;
}
.blog-slide__left__date {
  margin-bottom: 12px;
}
.blog-slide__left__eyebrow {
  margin-bottom: 12px;
}
@media (min-width: 960px) {
  .blog-slide__left {
    padding-top: 144px;
    padding-bottom: 144px;
  }
}

.blog-carousel {
  overflow: hidden;
  position: relative;
}
.blog-carousel__left-arrow {
  left: 15px;
}
.blog-carousel__right-arrow {
  right: 15px;
}

.blog-search {
  margin-top: 24px;
}

.event-detail {
  margin: 24px 0;
}
.event-detail__hosted {
  color: #0c5273;
}
.event-detail__header {
  word-break: break-word;
}
.event-detail__content {
  word-break: break-word;
}
.event-detail__content-container {
  margin-bottom: 0.5rem;
}
.event-detail__content-container a {
  transition: color 150ms ease-in-out;
}
.event-detail__content-container a:hover {
  color: #e06565;
}
.event-detail__speaker {
  margin-bottom: 0;
}
.event-detail__image {
  width: 100%;
  margin-bottom: 24px;
}
.event-detail__related-practices {
  margin-top: 24px;
}
@media (max-width: 960px) {
  .event-detail__related-practices__practice {
    display: block;
  }
  .event-detail__related-practices__practice + .event-detail__related-practices__practice:before {
    content: "";
    margin-left: 0;
  }
  .event-detail__related-practices__practice + .event-detail__related-practices__practice:last-child:before {
    content: "";
    margin-right: 0;
  }
}
.event-detail__separator {
  margin-left: -4px;
  font-weight: 100;
  font-size: 21px;
}
@media (max-width: 960px) {
  .event-detail__separator {
    display: none;
  }
}
@media (min-width: 960px) {
  .event-detail__content-link {
    transition: transition(color);
  }
  .event-detail__content-link:hover {
    color: #c95555;
  }
}

.subscribe {
  width: 100%;
}
.subscribe__container {
  display: flex;
  flex-direction: column-reverse;
}
.subscribe__description {
  margin-top: 24px;
}
.subscribe__form {
  margin-top: 24px;
}
.subscribe__form__result-message {
  margin-top: 24px;
}
.subscribe__form__topics {
  margin-top: 24px;
}
.subscribe__form__topics__expand-container {
  cursor: pointer;
  width: 100%;
}
.subscribe__form__topics__add {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  display: flex;
  font-size: 18px;
}
.subscribe__form__topics__collection {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #b4b4b4;
  padding: 5px;
  padding-right: 25px;
  flex-wrap: wrap;
}
.subscribe__form__topics__placeholder {
  color: #7D7E81;
}
.subscribe__form__topics__list {
  overflow: hidden;
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #b4b4b4;
  border-top: 0px;
  position: fixed;
  justify-content: space-between;
  background-color: #ffffff;
  transition: transform 0.35s ease-out;
  z-index: 5;
}
.subscribe__form__topics__list--active {
  transform: translateY(0);
  transition: transform 0.35s ease-out;
}
.subscribe__form__topics__list__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  background-color: #156289;
  color: #ffffff;
  align-items: center;
}
.subscribe__form__topics__list__header__close {
  cursor: pointer;
}
.subscribe__form__topics__list__footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #b4b4b4;
}
.subscribe__form__topics__list__footer__count {
  color: #7D7E81;
}
.subscribe__form__topics__list__footer__close {
  color: #e06565;
  cursor: pointer;
}
.subscribe__form__topics__list__container {
  overflow-y: scroll;
  align-items: center;
  padding: 5px;
  flex-grow: 1;
}
.subscribe__form__topics__list__topic {
  display: flex;
  margin: 15px 0;
}
.subscribe__form__topics__list__topic__checkbox {
  -webkit-appearance: checkbox;
}
.subscribe__form__submit {
  width: 100%;
}
.subscribe__form__submit__required {
  margin-bottom: 24px;
}
.subscribe__form__input {
  margin-bottom: 24px;
}
.subscribe__form__input__invalid-message {
  color: #e06565;
  display: none;
  padding-left: 10px;
  text-transform: none;
}
.subscribe__form__input__invalid-message--active {
  display: inline-block;
}
.subscribe__form__input__label {
  margin-bottom: 10px;
  display: flex;
}
.subscribe__form__input__label__error {
  color: #e06565;
}
.subscribe__form__input--hidden {
  display: none;
}
.subscribe__form__input__input {
  width: 100%;
  padding: 5px;
  border: 1px solid #b4b4b4;
}
.subscribe__form__input__input--multi {
  height: 150px;
}
@media (max-width: 720px) {
  .subscribe__form__input__input--multi {
    box-sizing: border-box;
  }
}
.subscribe__form__input__input--error {
  border-color: #e06565;
}
@media (max-width: 720px) {
  .subscribe .subscribe__form__topics__list {
    height: 100% !important;
  }
}
@media (max-width: 960px) {
  .subscribe__intro {
    margin-top: 1.5rem;
  }
  .subscribe__footer {
    margin-top: 1.5rem;
  }
}
@media (min-width: 720px) {
  .subscribe {
    min-height: 513px;
  }
  .subscribe__form {
    margin-right: 94px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .subscribe__form__input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-basis: 48.5%;
    box-sizing: border-box;
  }
  .subscribe__form__input--hidden {
    display: none;
  }
  .subscribe__form__input--half {
    flex-basis: 48.5%;
  }
  .subscribe__form__input--full {
    flex-basis: 100%;
  }
  .subscribe__form__input--third {
    flex-basis: 23%;
  }
  .subscribe__form__input__input--multi {
    width: 100%;
    box-sizing: border-box;
  }
  .subscribe__form__topics {
    width: 100%;
  }
  .subscribe__form__topics__add {
    top: 5px;
  }
  .subscribe__form__topics__list {
    position: relative;
    transform: translateY(0);
    visibility: hidden;
    transition: visibility 0.35s ease-out;
  }
  .subscribe__form__topics__list--active {
    visibility: visible;
    display: flex;
  }
  .subscribe__form__topics__list__header {
    display: none;
  }
}
@media (min-width: 960px) {
  .subscribe__container {
    margin-top: 48px;
    flex-direction: row;
  }
  .subscribe__container--shrink-padding {
    margin-top: 24px;
  }
  .subscribe__description {
    flex-basis: 33.33%;
  }
  .subscribe__form-wrapper {
    flex-basis: 62.66%;
  }
  .subscribe__result-message {
    flex-basis: 62.66%;
  }
}

.topic {
  background-color: #3da3a3;
  color: #ffffff;
  margin: 2px;
  padding: 2px 4px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
}
.topic__close {
  display: inline-flex;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: white;
  border: 2px solid black;
  box-sizing: border-box;
}
.styled-checkbox:hover + label:before {
  background: #3da3a3;
}
.styled-checkbox:checked + label:before {
  background: #3da3a3;
  border: 0px;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 8px;
  background: #3da3a3;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.people-search {
  width: 100%;
}
.people-search__filters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  align-items: flex-start;
}
.people-search__filters__left {
  width: 100%;
  margin-bottom: 24px;
}
.people-search__filters__right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.people-search__filters__container {
  width: 100%;
}
.people-search__filters__filter {
  margin: 12px 0px;
}
.people-search__search {
  color: #3da3a3;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #b4b4b4;
}
.people-search__search:before {
  font-size: 21px;
}
.people-search__search__input {
  margin-left: 10px;
}
.people-search__search__input::placeholder {
  color: #000000;
}
.people-search__search:focus-within {
  border-color: #3da3a3;
}
.people-search__people {
  margin-top: 24px;
}
.people-search__clear {
  margin-top: 24px;
}
.people-search__see-all {
  margin-top: 24px;
}
@media (min-width: 960px) {
  .people-search__filters {
    flex-direction: row;
  }
  .people-search__filters__left {
    flex-basis: 33.33%;
  }
  .people-search__filters__right {
    flex-basis: 56.66%;
  }
  .people-search__filters__container {
    max-width: 200px;
  }
  .people-search__filters__filter {
    width: 100%;
    margin-top: 0px;
  }
  .people-search__search {
    width: 350px;
  }
}

.global-search {
  width: 100%;
  flex-grow: 1;
}
.global-search__results {
  width: 100%;
  margin-top: 5rem;
}
.global-search__results__more {
  margin-top: 48px;
}
.global-search__results__found {
  margin: 24px 0;
}
.global-search__results__found__result__bold {
  cursor: pointer;
}
.global-search__results__found__result__bold:not(.global-search__results__found__result__bold--active) {
  color: #000000;
  border-bottom: 2px solid #000000;
  padding-bottom: 1px;
}
.global-search__results__found__result__bold:not(.global-search__results__found__result__bold):hover {
  color: #000000;
  border-bottom: 2px solid #000000;
  padding-bottom: 1px;
}
.global-search__results__found__result__bold--people, .global-search__results__found__result__bold--people:hover {
  color: #3da3a3;
  border-bottom: 2px solid #3da3a3;
  padding-bottom: 1px;
}
.global-search__results__found__result__bold--insights, .global-search__results__found__result__bold--insights:hover {
  color: #156289;
  border-bottom: 2px solid #156289;
  padding-bottom: 1px;
}
.global-search__results__found__result__bold--expertise, .global-search__results__found__result__bold--expertise:hover, .global-search__results__found__result__bold--services, .global-search__results__found__result__bold--services:hover {
  color: #e06565;
  border-bottom: 2px solid #e06565;
  padding-bottom: 1px;
}
.global-search__results__found__result__bold--other, .global-search__results__found__result__bold--other:hover {
  color: #048db6;
  border-bottom: 2px solid #048db6;
  padding-bottom: 1px;
}
.global-search__results__found--categories {
  display: inline;
}
.global-search__results__found--categories .global-search__results__found__result + .global-search__results__found__result:last-child:before {
  margin: 0 0.333rem;
}
.global-search__fixed {
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  top: 66px;
  padding-top: 2.5rem;
}
.global-search__search {
  position: relative;
  width: 100%;
  color: #3da3a3;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}
.global-search__search:before {
  font-size: 30px;
}
.global-search__search:after {
  content: "";
  width: calc(100% - 5rem);
  height: 1px;
  background-color: #b4b4b4;
  position: absolute;
  bottom: 0px;
  left: 2.5rem;
}
.global-search__search__clear {
  font-size: 30px;
  cursor: pointer;
  color: #3da3a3;
  font-weight: 200;
}
.global-search__search__input {
  margin-left: 15px;
  width: 100%;
}
.global-search__search__input::placeholder {
  color: #3da3a3;
}
.global-search__search:focus-within:after {
  background-color: #3da3a3;
}
@media (min-width: 720px) {
  .global-search__fixed {
    padding-top: 5rem;
  }
}

.global-search-home {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.global-search-home .container {
  flex-grow: 1;
}

.modal {
  position: fixed;
  top: calc(66px + 20px);
  left: 0px;
  top: 0;
  background-color: rgba(247, 248, 248, 0.9);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.modal__close {
  cursor: pointer;
  align-self: flex-end;
}
.modal__inner {
  background-color: #ffffff;
  width: 70%;
  margin: 0 auto;
  padding: 30px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 90%;
}
.modal__inner__text {
  margin-top: 46px;
  width: 100%;
}
.modal__inner__form-modal {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.modal__inner__header {
  margin-bottom: 24px;
}
.modal__inner__subheader {
  margin-bottom: 48px;
}
@media (max-width: 720px) {
  .modal__inner {
    width: 100%;
    height: 100%;
  }
  .modal__inner__header {
    margin-bottom: 48px;
  }
  .modal__inner__subheader {
    margin-bottom: 40px;
  }
  .modal__inner__text {
    margin-top: 24px;
  }
  .modal__inner__text.rich-text {
    font-size: 1rem;
  }
  .modal__inner__text.rich-text p {
    font-size: 1rem;
  }
}

.share-links__links {
  margin: 9px 0px;
  display: flex;
  padding-left: 16px;
}
.share-links__links__link {
  margin-right: 8px;
}

.sitesearch-listing {
  color: #000000;
  margin-top: 1.7em;
}
.sitesearch-listing__name {
  line-height: 1.333rem;
}
.sitesearch-listing__link .sitesearch-listing__name {
  transition: color 0.35s ease-out;
}
.sitesearch-listing__link .sitesearch-listing__name:hover {
  color: #000000;
}
.sitesearch-listing__sub-line {
  margin-bottom: 5px;
}
.general-content__rich-text__list {
  margin-top: 24px;
}

.about {
  display: flex;
  flex-direction: column-reverse;
}
.about .section__aside {
  border-bottom: 0px;
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 0px;
  border-top: solid 0.0625rem #b4b4b4;
  margin-bottom: 0px;
}
@media (min-width: 720px) {
  .about {
    flex-direction: row-reverse;
  }
  .about .section__aside {
    border-top: 0px;
    margin-top: 0px;
    padding-top: 0px;
  }
}

.search-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s linear;
}
.search-loader__show {
  visibility: visible;
  opacity: 1;
}
.search-loader__show .search-loader__spinner {
  border: 15px solid #7d7e81;
  border-top: 15px solid #156289;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.interactive-maps__tabs {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.interactive-maps__tab {
  color: #7D7E81;
  margin-right: 40px;
}
.interactive-maps__tab:last-child {
  margin-right: 0;
}
.interactive-maps__tab--active {
  color: #000000;
}
.interactive-maps__tab:hover {
  color: #000000;
}
.interactive-maps__map-wrapper {
  background-color: #E6E7E9;
  padding: 20px 0;
}
.interactive-maps__map {
  width: 100%;
  height: 300px;
  background-color: #E6E7E9;
}
.interactive-maps__download-wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 960px) {
  .interactive-maps__map {
    height: 500px;
  }
}

@media (min-width: 480px) {
  .twitter-timeline-component__left.component-container__body__left {
    flex-basis: 40%;
  }
  .twitter-timeline-component__right.component-container__body__right {
    flex-basis: 60%;
  }
}

.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f7f8f8;
  z-index: 5;
}
.cookie__inner {
  display: flex;
  margin: 1rem auto;
  width: 75%;
}
.cookie__inner__text {
  font-size: 18px;
  font-weight: 500;
  margin-right: 100px;
}
.cookie__inner__button {
  display: flex;
  align-items: center;
}
.cookie__alternate {
  background-color: #c95555;
  opacity: 0.9;
}
.cookie__alternate .cookie__inner__text {
  color: #ffffff;
}
.cookie__alternate .cookie__inner__text a {
  transition: color 0.35s ease-out, opacity 150ms ease-in-out;
}
.cookie__alternate .cookie__inner__text a:hover {
  opacity: 0.6;
  color: #ffffff !important;
}
.cookie__alternate .cookie__inner__button button {
  color: #ffffff;
}
@media (max-width: 1440px) {
  .cookie__inner {
    width: 90%;
  }
  .cookie__inner__text {
    margin-right: 50px;
  }
}
@media (max-width: 720px) {
  .cookie__inner {
    width: 95%;
    margin: 1rem;
    display: block;
  }
  .cookie__inner__text {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.rss__landing .section--component {
  padding-top: 48px;
  padding-bottom: 48px;
  border-top: solid 1px #b4b4b4;
}
.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.flex-table {
  width: 100%;
}
.flex-table table {
  table-layout: fixed;
}
.flex-table #flexTable.table-slideDown .sticky-table-header.fixed-header {
  top: 8.25rem !important;
}
@media all and (-ms-high-contrast: none) {
  .flex-table #flexTable.table-slideDown.table-slideDown .flex-table__headrow {
    top: 7.93rem !important;
  }
}
.flex-table .sticky-table-header.fixed-header {
  background: white !important;
  display: table !important;
  max-width: 70rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  position: fixed !important;
  table-layout: fixed;
  top: 4rem !important;
  width: 100% !important;
  z-index: 100 !important;
}
@media all and (-ms-high-contrast: none) {
  .flex-table .sticky-table-header.fixed-header .flex-table__headrow {
    background: white !important;
    display: table;
    left: 50% !important;
    max-width: 70rem;
    position: fixed;
    right: 0px;
    top: 63px !important;
    transform: translate3d(-50%, 0px, 0px);
    width: 100%;
    z-index: 1 !important;
  }
}
.flex-table table {
  width: 100%;
}
.flex-table table th {
  padding-right: 0;
}
.flex-table__filters {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #b4b4b4;
}
.flex-table__filters--header {
  font-weight: 400;
}
.flex-table__filters .datepicker__container {
  display: inline-block;
}
.flex-table__filters .datepicker__container .regular-input {
  width: 257px;
}
.flex-table__filters .regular-input {
  min-width: 250px;
}
.flex-table__filters .regular-input__datepicker {
  padding: 0;
}
.flex-table__filters .regular-input__datepicker:hover {
  outline: 1px solid #3da3a3;
}
.flex-table__filters .regular-input__datepicker--placeholder {
  position: absolute;
  pointer-events: none;
  padding: 14px;
}
.flex-table__filters .regular-input__datepicker input {
  padding: 15px;
}
.flex-table__filters .regular-input__datepicker .vc-popover-content {
  border: 1px solid #3da3a3;
  border-radius: 0;
}
.flex-table__filters .regular-input__datepicker .vc-shadow {
  box-shadow: none;
}
.flex-table__filters .regular-input .regular-dropdown__select .icon, .flex-table__filters .regular-input .regular-dropdown__select .link--external, .flex-table__filters .regular-input .regular-dropdown__select .regular-input__search, .flex-table__filters .regular-input .regular-dropdown__select .people-search__search, .flex-table__filters .regular-input .regular-dropdown__select .global-search__search {
  position: absolute;
  right: 14px;
}
.flex-table__filters .multiselect--active {
  outline: 1px solid #3da3a3;
}
.flex-table__filters .multiselect-checkboxes {
  list-style: none;
  margin: 0;
  margin-top: 0 !important;
  padding: 0;
  position: absolute;
  left: 0;
  background: #ffffff;
  width: 100%;
  outline: 1px solid #3da3a3;
  z-index: 1;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.flex-table__filters .multiselect-checkboxes--expanded {
  max-height: 1000px;
  opacity: 1;
  visibility: visible;
}
.flex-table__filters .multiselect-checkboxes__input {
  display: flex;
  margin: 14px 0;
  padding: 0 14px;
}
.flex-table__filters .multiselect-checkboxes__input input {
  -webkit-appearance: checkbox;
}
.flex-table__filters .multiselect-checkboxes__input input:checked + label:after {
  top: 10px;
}
.flex-table__filters .multiselect-checkboxes__button {
  border-top: 1px solid #D8D8D8;
}
.flex-table__filters .multiselect-checkboxes__button button {
  padding: 10px 0;
  text-align: center;
  width: 100%;
  color: #e06565;
  cursor: pointer;
}
.flex-table__filters .active-filters {
  list-style: none;
  padding: 0;
  margin-right: 0;
}
.flex-table__filters .active-filters__filter {
  display: inline;
}
.flex-table__filters .active-filters__filter .button {
  background-color: #3da3a3;
  color: #ffffff;
  border: none;
  margin-right: 5px;
  margin-bottom: 5px;
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
}
.flex-table__filters .active-filters__filter .button:hover {
  padding: 8px 20px;
  padding-bottom: 7px;
  margin: 0 5px 5px 0;
  background-color: #318c89;
}
.flex-table__filters .active-filters__filter--reset button {
  background-color: transparent !important;
  color: #0b1a2b !important;
  text-decoration: underline;
  padding: 0 !important;
}
.flex-table__filters .active-filters__filter--reset button:hover {
  color: #c95555 !important;
}
.flex-table__filters-container {
  display: inline-block;
}
.flex-table__filters .expand-filters {
  display: none;
}
.flex-table__header {
  cursor: pointer;
}
.flex-table__row {
  display: none;
}
.flex-table__row--visible {
  display: table-row;
}
.flex-table__row--header {
  display: none;
}
.flex-table__row ul {
  list-style: none;
  padding-left: 0;
  margin-right: 0;
}
.flex-table__row ul li {
  margin-bottom: 10px;
}
.flex-table__row--bullet {
  display: none;
}
.flex-table .insights-search__more {
  margin-top: 20px;
}
.flex-table .icon, .flex-table .link--external, .flex-table .regular-input__search, .flex-table .people-search__search, .flex-table .global-search__search {
  display: inline-block;
  transition: transform 0.5s ease;
}
.flex-table .icon--flip {
  transform: rotate(180deg) !important;
}
.flex-table .icon--rotate {
  transform: rotate(45deg) !important;
}
.flex-table--mobileonly {
  display: none;
}
.flex-table .insights-search__insights__no-results {
  margin-top: 2rem;
}
@media (max-width: 960px) {
  .flex-table table {
    margin-top: 14px;
  }
  .flex-table thead tr {
    display: none;
  }
  .flex-table__filters .regular-input {
    margin-bottom: 0;
    margin-right: 0;
  }
  .flex-table__filters--label {
    color: #7D7E81;
    font-size: 14px;
  }
  .flex-table__filters-container {
    display: block;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }
  .flex-table__filters-container .regular-input:first-child {
    margin-top: 0;
  }
  .flex-table__filters-container--expanded {
    max-height: 1000px;
    opacity: 1;
    visibility: visible;
    border: 1px solid #3da3a3;
    border-top: none;
    padding: 14px;
    padding-bottom: 0;
  }
  .flex-table__filters-container--expanded .multiselect {
    outline: none;
  }
  .flex-table__filters-container--expanded .multiselect button {
    display: none;
  }
  .flex-table__filters-container--expanded .multiselect ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    outline: 0;
    list-style: none;
    padding-left: 0;
    outline: 0;
    border: none;
  }
  .flex-table__filters-container--expanded .multiselect ul .multiselect-checkboxes__button {
    display: none;
  }
  .flex-table__filters-container--expanded .multiselect ul .multiselect-checkboxes__input {
    margin: 7px 0;
    width: 50%;
    padding: 0;
  }
  .flex-table__filters-container--expanded .multiselect ul .multiselect-checkboxes__input--selectall {
    width: 100%;
  }
  .flex-table__filters-container--expanded .datepicker__container {
    margin-top: 14px;
    width: 100%;
  }
  .flex-table__filters-container--expanded .datepicker__container .regular-input {
    margin-top: 0;
    width: 100%;
  }
  .flex-table__filters-container--expanded .regular-input__datepicker--placeholder {
    display: none;
  }
  .flex-table__filters-container--expanded .regular-input__datepicker .vc-container.vc-relative.vc-reset {
    width: 100%;
    border: none;
  }
  .flex-table__filters-container ul.regular-input {
    list-style: none;
    padding-left: 0;
    outline: 0;
    border: none;
  }
  .flex-table__filters-container ul.regular-input .regular-input__button {
    padding: 7px 0;
  }
  .flex-table__filters-container .filter-actions {
    display: flex;
    justify-content: space-around;
  }
  .flex-table__filters-container .filter-actions button {
    padding: 14px;
  }
  .flex-table__filters-container .filter-actions__apply {
    color: #e06565;
  }
  .flex-table__filters-container .filter-actions__reset {
    text-decoration: underline;
  }
  .flex-table__filters .expand-filters {
    display: flex;
    justify-content: space-between;
  }
  .flex-table__filters .expand-filters .regular-input {
    min-width: auto;
    width: calc(50% - 14px);
    transition: width 0.5s ease;
    margin-right: 0;
  }
  .flex-table__filters .expand-filters .regular-input .icon, .flex-table__filters .expand-filters .regular-input .link--external, .flex-table__filters .expand-filters .regular-input .regular-input__search, .flex-table__filters .expand-filters .regular-input .people-search__search, .flex-table__filters .expand-filters .regular-input .global-search__search {
    position: absolute;
    right: 14px;
  }
  .flex-table__filters .expand-filters .regular-input--expanded {
    width: 100%;
    color: #3da3a3;
    border: 1px solid #3da3a3;
    border-bottom: none;
    outline: 0;
  }
  .flex-table__filters .expand-filters .regular-input--collapsed {
    width: 0;
    visibility: hidden;
    margin: 0;
  }
  .flex-table__filters .expand-filters__button {
    padding: 14px;
    width: 100%;
  }
  .flex-table__row {
    margin-bottom: 0;
    border: none;
  }
  .flex-table__row--title {
    font-size: 1.25rem !important;
    font-weight: bold !important;
  }
  .flex-table__row--title .flex-table__row--header {
    display: none;
  }
  .flex-table__row--meta {
    display: inline !important;
    width: auto !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem !important;
    color: #3da3a3;
    white-space: nowrap;
  }
  .flex-table__row--meta:first-child:before {
    content: "";
    display: block;
    margin-top: 1rem;
  }
  .flex-table__row--meta.flex-table__row--empty {
    visibility: hidden !important;
  }
  .flex-table__row--meta.flex-table__row--empty span {
    padding: 0;
  }
  .flex-table__row--meta .flex-table__row--data {
    display: inline;
    white-space: normal;
  }
  .flex-table__row--meta .flex-table__row--header {
    display: none;
  }
  .flex-table__row--meta + .flex-table__row--title {
    margin-top: 0.5rem;
  }
  .flex-table__row--bullet {
    padding: 0 !important;
  }
  .flex-table__row--header {
    font-weight: 600;
    width: 40%;
  }
  .flex-table__row--data {
    width: 60%;
  }
  .flex-table__row td {
    max-width: 100% !important;
    width: 100%;
    display: table;
    margin-bottom: calc(0.5rem + 8px);
  }
  .flex-table__row td:first-child {
    margin-top: 0.5rem;
  }
  .flex-table__row td span {
    display: table-cell;
    padding: 0 10px;
  }
  .flex-table--mobileonly {
    display: block;
  }
}

/* IE10+ CSS styles go here */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vc-container {
    width: 258px;
    height: 269px;
  }
  .vc-container .vc-w-full {
    width: 100%;
    height: 100%;
  }
  .vc-container .vc-grid-container {
    height: 100%;
    display: -ms-grid !important;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
    -ms-grid-rows: auto;
    grid-template-rows: repeat(4, auto);
    -ms-grid-gap: 30px;
    grid-gap: 30px;
  }
  .vc-container .vc-grid-container .vc-nav-container .vc-grid-container {
    -ms-grid-columns: 1fr 1fr 1fr;
  }
  .vc-container .vc-grid-container .vc-nav-container[data-v-d8853504] .vc-grid-container[data-v-29dcba24]:first-child {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
  }
  .vc-container .vc-grid-container .vc-nav-container[data-v-d8853504] .vc-grid-container[data-v-29dcba24]:first-child .vc-grid-cell-col--2 {
    margin-top: 5px;
  }
  .vc-container .vc-grid-container .vc-grid-cell.vc-grid-cell-row-1.vc-grid-cell-row--1.vc-grid-cell-col-1.vc-grid-cell-col--1 {
    min-height: 266px;
    min-width: 255px;
  }
  .vc-container .vc-grid-container .vc-grid-cell.vc-grid-cell-row-1.vc-grid-cell-row--1.vc-grid-cell-col-1.vc-grid-cell-col--1 .vc-header {
    position: relative;
    top: 0;
    width: 100%;
    text-align: center;
  }
  .vc-container .vc-grid-container .vc-grid-cell.vc-grid-cell-row-1.vc-grid-cell-row--1.vc-grid-cell-col-1.vc-grid-cell-col--1 .vc-grid-container .vc-grid-cell {
    margin-bottom: 10px;
  }
  .vc-container .vc-popover-caret.direction-bottom.align-left {
    display: none;
  }
  span.vc-popover-caret.direction-bottom.align-left {
    display: none;
  }
  .regular-input__datepicker .vc-popover-content-wrapper {
    margin-top: 10px;
  }
  .vc-grid-container {
    overflow: hidden;
  }
  .vc-popover-content-wrapper {
    overflow: hidden;
    -ms-overflow-style: none;
  }
  .vc-bg-gray-800 {
    width: 164px !important;
  }
  .vc-grid-cell {
    display: block !important;
  }
  .vc-highlight {
    width: 28px !important;
    height: 28px !important;
    margin: 0 !important;
  }
  .vc-day {
    width: 35px !important;
  }
  .vc-day-content {
    width: 28px !important;
    height: 28px !important;
  }
  .vc-day-content:hover {
    background-color: rgba(204, 214, 224, 0.3) !important;
  }
}
.emergency-banner {
  position: relative;
  top: 0;
  bottom: auto;
  color: #ffffff;
  background-color: #3da3a3;
  padding: 0 18px;
}
.emergency-banner .cookie__inner {
  width: 100%;
  justify-content: space-between;
  margin: 0;
  padding: 1rem 0;
}
.emergency-banner .cookie__inner__text {
  margin-right: 0;
  line-height: 1.25;
  margin-bottom: 0;
}
.emergency-banner .cookie__inner__text a:hover {
  color: #ffffff;
}
.emergency-banner .cookie__inner__text a:visited {
  color: #ffffff;
}
.emergency-banner .cookie__inner__text a:focus {
  color: #ffffff;
}
@media (max-width: 720px) {
  .emergency-banner .cookie__inner {
    width: 100%;
    margin: 0;
    display: flex;
  }
}
@media (min-width: 960px) {
  .emergency-banner {
    padding: 0 5vw;
  }
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  overflow-y: hidden;
}
#onetrust-consent-sdk .cookie-setting-link {
  color: #e06565 !important;
}

.homepage-inquiry__footer button.ot-sdk-show-settings,
footer.footer button.ot-sdk-show-settings {
  border: none !important;
  padding: 0 !important;
  opacity: 0.5 !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.375rem !important;
  color: #ffffff !important;
  padding-bottom: 1px !important;
  transition: color, opacity, border-color 150ms ease-in-out !important;
  display: inline;
}
.homepage-inquiry__footer button.ot-sdk-show-settings:hover,
footer.footer button.ot-sdk-show-settings:hover {
  background-color: transparent !important;
  opacity: 1 !important;
}

.rich-text .ot-sdk-show-settings {
  border: none !important;
  padding: 0 !important;
  color: #000000 !important;
  font-size: 21px !important;
  transition: color 150ms ease-in-out, border-bottom-color 150ms ease-in-out !important;
  border-bottom: solid 1px #000000 !important;
}
.rich-text .ot-sdk-show-settings:hover, .rich-text .ot-sdk-show-settings:focus {
  background-color: transparent !important;
  color: #c95555 !important;
  border-bottom-color: #c95555 !important;
}

.insight-detail h4 {
  margin-bottom: 8px;
}

.navbar__wrapper,
.tabs__container {
  display: none;
}

* {
  -webkit-print-color-adjust: exact;
}

.people-search__filters,
.people-search__more,
.insights-search__filters,
.button__see-more,
.team-landing__header__filters,
.insights-search__more,
.team-landing__more {
  display: none;
}

.component-card-container .component-card {
  margin-bottom: 1rem;
}
.component-card-container .component-card .component-card__icon,
.component-card-container .component-card .component-card__more {
  display: none;
}

.contacts__container .contact-card,
.team-landing__landing .contact-card {
  margin-bottom: 0;
  width: 100%;
}
.contacts__container .contact-card__image,
.team-landing__landing .contact-card__image {
  display: none;
}

.bio-hero__mobile-image,
.section__aside {
  display: none;
}

.experience .button__container {
  display: none;
}

footer {
  display: none;
}

em {
  font-family: "Segoe UI";
}

.section__main {
  width: 100%;
}

.section--component {
  display: block;
}

.cookie.cookie__alternate {
  display: none;
}

.rich-text__responsive-table {
  max-width: 100%;
}

.rich-text table {
  width: 100%;
}

.section--component, .component-card-container {
  display: block;
}

.component-card-container--three .component-card, .component-card-container--full .component-card {
  width: 100%;
}